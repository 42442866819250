import React from "react";
import axios from "axios";

import appleWalletButton from "../../assets/buttons/apple_wallet_button.png";
import UserProfileAppleWalletPreview from "../../components/UserProfileAppleWalletPreview";
import phoneFrame from "../../assets/mockups/phone-frame.png"; // Go up two levels and then to assets/mockups
import "../../assets/css/custom.css"; // Ensure the path is correct
import "../../assets/css/UserProfileForm.css";

const AppleWalletCardProfile = ({
  profile,
  handleChange,
  handleSubmit,
  updateMessage,
  messageType,
  navigate,
}) => {
  const handleDownloadPass = async () => {
    try {
      const { data } = await axios.get(
        "https://dev.keepme.gr/wallet_passes/generate_signed_url/",
        {
          headers: { Authorization: `Token ${localStorage.getItem("token")}` },
        }
      );
      window.location.href = data.signedUrl;
    } catch (error) {
      console.error("Error downloading the digital business card:", error);
      if (error.response && error.response.status === 401) {
        navigate("/login");
      }
    }
  };

  return (
    <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
      <h1 className="headline-text pt-xl-1 pb-3">My Apple Wallet Card</h1>
      {/* Basic info */}
      <h2 className="h3 headers-text headers-green mb-4">Card</h2>

      <div className="row pb-5 border-bottom mb-4 justify-content-center">
        {/* Info text */}
        <div className="col-12 mb-2">
          <p>
            Add your business card to the apple wallet on your iPhone for easy
            sharing.
          </p>
        </div>
        <div className="col-4 ">
          <img
            src={appleWalletButton}
            alt="Download to Apple Wallet"
            className="btn-image" // Use this class for any needed styling
            onClick={handleDownloadPass}
            style={{ cursor: "pointer" }} // Makes it clear that the image is clickable
          />
        </div>
      </div>

      <h2 className="h3 headers-text headers-green mb-4">Wallet Card Style</h2>
      <div className="d-md-flex justify-content-around">
        <div className="col-sm-12 col-lg-6  align-self-center">
          <form
            className="needs-validation  pb-2 pb-lg-4"
            noValidate
            onSubmit={handleSubmit}
          >
            {/* Company Card Color */}
            <div className="col-12 mb-4">
              <label
                htmlFor="company_card_color"
                className="form-label fs-base"
              >
                Card Font Color
              </label>
              <div className="d-flex justify-content-center">
                <div className="col-3">
                  <input
                    type="color"
                    id="company_wallet_card_fontcolor"
                    name="company_wallet_card_fontcolor"
                    value={profile.company_wallet_card_fontcolor || "#ffffff"}
                    className="form-control form-control-lg me-2"
                    // style={{ width: "auto" }}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-3 offset-1">
                  <input
                    type="text"
                    id="company_wallet_card_fontcolor_hex"
                    name="company_wallet_card_fontcolor"
                    value={profile.company_wallet_card_fontcolor || "#ffffff"}
                    className="form-control form-control-lg"
                    onChange={handleChange}
                    placeholder="#ffffff"
                  />
                </div>
              </div>
            </div>

            {/* Apple Wallet Card Background Color */}
            <div className="col-12 mb-4">
              <label
                htmlFor="company_card_color"
                className="form-label fs-base"
              >
                Card Background Color
              </label>
              <div className="d-flex justify-content-center">
                <div className="col-3  ">
                  <input
                    type="color"
                    id="company_wallet_card_bgcolor"
                    name="company_wallet_card_bgcolor"
                    value={profile.company_wallet_card_bgcolor || "#ffffff"}
                    className="form-control form-control-lg me-2"
                    // style={{ width: "auto" }}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-3 offset-1">
                  <input
                    type="text"
                    id="company_wallet_card_bgcolor_hex"
                    name="company_wallet_card_bgcolor"
                    value={profile.company_wallet_card_bgcolor || "#ffffff"}
                    className="form-control form-control-lg"
                    onChange={handleChange}
                    placeholder="#ffffff"
                  />
                </div>
              </div>
            </div>

            <div className="d-flex mb-3 justify-content-center gap-3 pt-5">
              <button type="reset" className="cancel-changes">
                Cancel
              </button>
              <button type="submit" className="save-changes">
                Save Changes
              </button>
              {updateMessage && (
                <div
                  style={{
                    color: messageType === "success" ? "green" : "red",
                    marginTop: "10px",
                  }}
                >
                  {updateMessage}
                </div>
              )}
            </div>
          </form>
        </div>

        <div className="col-lg-6 col-md-12">
          <div
            className="awpc-preview-phone position-relative"
            style={{
              height: "600px",
              width: "100%",
              maxWidth: "300px",
              margin: "0 auto",
            }}
          >
            {/* Place the phone frame as a background image */}
            <img
              src={phoneFrame}
              className="img-fluid"
              alt="Phone Frame"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                // objectFit: "cover",
                zIndex: 2,
              }}
            />

            {/* Conditionally rendered user profile card */}
            <div
              className="position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center"
              style={{
                width: "80%", // Adjust width to fit within the phone image
                height: "80%", // Adjust height to fit within the phone image
                zIndex: 1,
              }}
            >
              {profile.company_name && profile.username ? (
                <UserProfileAppleWalletPreview
                  companyName={profile.company_name}
                  username={profile.username}
                  company_wallet_card_fontcolor={
                    profile.company_wallet_card_fontcolor
                  }
                  company_wallet_card_bgcolor={
                    profile.company_wallet_card_bgcolor
                  }
                />
              ) : (
                <div className="text-center">Waiting for profile data...</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppleWalletCardProfile;
