// src/UserProfile.js
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import instaIcon from "../../../assets/buttons/instagramBlack.png";
import facebookIcon from "../../../assets/buttons/facebookBlack.png";
import tiktokIcon from "../../../assets/buttons/TikTokBlack.png";
import linkedinIcon from "../../../assets/buttons/linkedinBlack.png";
import keepme from "../../../assets/buttons/keepmeBlack.png";
import { useParams } from "react-router-dom";
import "../../../assets/css/TemplatesCSS/fluro.css"; // Ensure this CSS file exists and styles your components
import { saveAs } from "file-saver";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";

function Fluro({ companyName, username, templateColor, isPreview }) {
  const params = useParams();
  const [profile, setProfile] = useState(null);

  const baseImageUrl = "https://dev.keepme.gr"; // URL to prepend to the image path
  const logoContainerRef = useRef(null); // Ref for the logo container

  useEffect(() => {
    if (params.companyName && params.username) {
      axios
        .get(
          `https://dev.keepme.gr/api/card/${params.companyName}/${params.username}/`
        )
        .then((response) => {
          setProfile(response.data);
          if (response.data.company_card_color) {
            document.documentElement.style.setProperty(
              "--card-color",
              response.data.company_card_color
            );
          }
          // Dynamically import CSS based on industry
          // importIndustryCss(response.data.industry);
        })
        .catch((error) =>
          console.error(
            "There was an error retrieving the profile data:",
            error
          )
        );
    } else {
      axios
        .get(`https://dev.keepme.gr/api/card/${companyName}/${username}/`)
        .then((response) => {
          setProfile(response.data);
          if (response.data.company_card_color) {
            document.documentElement.style.setProperty(
              "--card-color",
              response.data.company_card_color
            );
          }
          // Dynamically import CSS based on industry
          // importIndustryCss(response.data.industry);
        })
        .catch((error) =>
          console.error(
            "There was an error retrieving the profile data:",
            error
          )
        );
    }
  }, [companyName, username, params.companyName, params.username]);

  useEffect(() => {
    window.addEventListener("load", adjustHeightForMobileUI);
    window.addEventListener("resize", adjustHeightForMobileUI);

    // Cleanup listeners on component unmount
    return () => {
      window.removeEventListener("load", adjustHeightForMobileUI);
      window.removeEventListener("resize", adjustHeightForMobileUI);
    };
  }, []);

  function adjustHeightForMobileUI() {
    const viewportHeight = window.innerHeight; // Get the actual visible viewport height
    const profileCard = document.querySelector(".profile-card"); // Select your main container

    if (profileCard) {
      profileCard.style.height = `${viewportHeight * 0.9}px`; // Adjust to 90% of the viewport height, or as needed
    }
  }

  window.addEventListener("load", adjustHeightForMobileUI);
  window.addEventListener("resize", adjustHeightForMobileUI);

  const downloadVCard = () => {
    const vCardData = [
      "BEGIN:VCARD",
      "VERSION:3.0",
      `N:${profile.last_name};${profile.first_name}`,
      `FN:${profile.first_name} ${profile.last_name}`,
      `TEL;TYPE=WORK,VOICE:${profile.telephone}`,
      `TEL;TYPE=MOBILE,VOICE:${profile.mobile}`,
      `EMAIL;TYPE=WORK,INTERNET:${profile.email}`,
      `ADR;TYPE=WORK:${profile.address};${profile.city};;${profile.postcode};${profile.country}`,
      `ORG:${profile.company}`,
      `TITLE:${profile.position}`,
      "END:VCARD",
    ].join("\n");

    const blob = new Blob([vCardData], { type: "text/vcard;charset=utf-8" });
    saveAs(blob, `${profile.first_name}_${profile.last_name}.vcf`);
  };

  if (!profile) {
    return <div>Loading...</div>;
  }

  // const cardStyle = {
  //   backgroundColor: profile.card_color || '#FFFFFF' // default color if none provided
  // };

  const profileActions = () => {
    // Define an array of objects for each social media type
    const socialMedia = [
      {
        type: "Instagram",
        company: profile.company_instagram_link,
        icon: instaIcon,
      },
      {
        type: "Facebook",
        company: profile.company_facebook_link,
        icon: facebookIcon,
      },
      {
        type: "TikTok",
        company: profile.company_tiktok_link,
        icon: tiktokIcon,
      },
      {
        type: "LinkedIn",
        company: profile.company_linkedin_link,
        icon: linkedinIcon,
      },
    ];

    const buttons = socialMedia
      .flatMap((media) => {
        // Handle cases where only a company link is provided
        const actions = [];
        if (media.personal) {
          actions.push(
            <button
              key={`${media.type}-personal`}
              className="upc-prv-btn"
              onClick={() => window.open(media.personal, "_blank")}
            >
              <FontAwesomeIcon icon={media.icon} className="upc-prv-icon" />
              <span className="upc-prv-btn-text">{`Personal ${media.type}`}</span>
            </button>
          );
        }
        if (media.company) {
          actions.push(
            <div
              key={`${media.type}-company`}
              className="fluro-media-button"
              onClick={() => window.open(media.company, "_blank")}
            >
              <img
                src={media.icon}
                className="fluro-media-button-resize"
                alt=""
              />
            </div>
          );
        }
        return actions;
      })
      .filter(Boolean); // Filter out undefined values (for missing links)

    return <>{buttons}</>;
  };

  const getClassName = (type) => {
    switch (type) {
      case "green":
        return "flurogreen-background";
      case "blue":
        return "fluroblue-background";
      default:
        return "fluro-background";
    }
  };

  return (
    <div
      className={`${
        params.card_template_color
          ? getClassName(params.card_template_color)
          : getClassName(templateColor)
      } ${isPreview ? "fluro-background-preview" : ""}`}
    >
      {" "}
      {/* Wrapper div to contain both the profile card and the footer */}
      <Helmet>
        <title>
          {profile ? `${profile.first_name} ${profile.last_name}` : "KeepMe"}
        </title>
      </Helmet>
      <div className={` ${isPreview ? "min-vw-100" : "fluro-body-container"}`}>
        {profile.profile_pic && (
          <div className="" ref={logoContainerRef}>
            <img
              src={`${baseImageUrl}${profile.profile_pic}`}
              alt="Company Logo"
              className="fluro-company-logo"
            />
          </div>
        )}
        <div className="fluro-company-name">{profile.company}</div>
        <div className="fluro-name-lastname">{profile.first_name} </div>
        <div className="fluro-name-lastname">{profile.last_name}</div>
        <div className="fluro-position">{profile.position}</div>
        <div className="fluro-pronouns">He / Him</div>
        <div className="fluro-podcast-button">
          <p>Podcast</p>
        </div>
        <div
          className="fluro-website-button"
          onClick={() => window.open(profile.company_website, "_blank")}
        >
          <p>Website</p>
        </div>
        <div className="fluro-portofolio-button">
          <p>Portofolio</p>
        </div>

        {profile.email && (
          <div>
            <p className="fluro-email-text">Email</p>
            <a href={`mailto:${profile.email}`} className="fluro-email">
              {profile.email}
            </a>
          </div>
        )}
        <div>
          <p className="fluro-phone-text">Telephone</p>
          {profile.telephone && (
            <a href={`tel:${profile.telephone}`} className="fluro-phone">
              {profile.telephone}
            </a>
          )}
          {profile.mobile && (
            <a href={`tel:${profile.mobile}`} className="fluro-mobile">
              {profile.mobile}
            </a>
          )}
        </div>

        <div className="fluro-container-media">{profileActions()}</div>
        <div className="fluro-addtoapplewallet-button">
          <p>Add to Apple Wallet</p>
        </div>
        <div className="fluro-addtocontacts-button" onClick={downloadVCard}>
          <p>
            {/* <FontAwesomeIcon
              icon={faAddressBook}
              className="fluro-addtocontacts-icon"
            /> */}
            Add to Contacts
          </p>
        </div>
      </div>
      <div className="fluro-footer">
        <span>Powered by </span>
        <a
          href="https://www.keepme.gr"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={keepme} alt="keepme.gr" />
        </a>
      </div>
    </div>
  );
}

export default Fluro;
