import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo.svg";
import signinBackground from "../../assets/img/account/signin-bg.jpg";

function JoinCompany() {
  const [inviteCode, setInviteCode] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://dev.keepme.gr/api/join-company/",
        { invite_code: inviteCode },
        {
          headers: { Authorization: `Token ${localStorage.getItem("token")}` },
        }
      );
      navigate("/profile");
    } catch (err) {
      setError(err.response.data.error || "Failed to join the company.");
    }
  };

  return (
    <>
      <header className="header navbar navbar-expand-lg position-absolute navbar-sticky">
        <div className="container px-3">
          <a href="/" className="navbar-brand pe-3">
            <img src={logo} width="47" alt="Silicon" />
          </a>
        </div>
      </header>

      <main className="page-wrapper">
        <section className="h-100 pt-5 pb-4">
          <div className="container d-flex flex-wrap justify-content-center justify-content-xl-start h-100 pt-5">
            <div
              className="w-100 align-self-end pt-1 pt-md-4 pb-4"
              style={{ maxWidth: "526px" }}
            >
              <h1 className="text-center text-xl-start">
                Join an Existing Company
              </h1>
              <form
                className="needs-validation"
                noValidate
                onSubmit={handleSubmit}
              >
                <div className="mb-4">
                  <label htmlFor="inviteCode" className="form-label fs-base">
                    Invite Code
                  </label>
                  <input
                    type="text"
                    id="inviteCode"
                    name="inviteCode"
                    className="form-control form-control-lg"
                    required
                    value={inviteCode}
                    onChange={(e) => setInviteCode(e.target.value)}
                  />
                </div>
                {error && (
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                )}
                <button
                  type="submit"
                  className="btn btn-primary shadow-primary btn-lg w-100"
                >
                  Join Company
                </button>
              </form>
            </div>
          </div>
          <div
            className="position-absolute top-0 end-0 w-50 h-100 bg-position-center bg-repeat-0 bg-size-cover d-none d-xl-block"
            style={{ backgroundImage: `url(${signinBackground})` }}
          />
        </section>
      </main>
    </>
  );
}

export default JoinCompany;
