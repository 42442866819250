import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logo from "../assets/img/homepage/keepme_logo_green.png"; // Adjust the import path as necessary
import avatar from "../assets/img/avatar/18.jpg";
import "../assets/vendor/boxicons/css/boxicons.min.css";
import "../assets/css/custom.css"; // Ensure the path is correct
import "../assets/css/profilepage.css";

import UserProfileForm from "../components/ProfilePageComp/UserProfileForm";
import CompanyProfileForm from "../components/ProfilePageComp/CompanyProfileForm";
import MobileDigitalCardProfile from "../components/ProfilePageComp/MobileDigitalCardProfile";
import AppleWalletCardProfile from "../components/ProfilePageComp/AppleWalletCardProfile";
import QRCodesProfile from "../components/ProfilePageComp/QRCodesProfile";
import HeadNavProfile from "../components/ProfilePageComp/HeadNavProfile";
import AccountSecurityProfile from "../components/ProfilePageComp/AccountSecurityProfile";
import TeamMembersProfile from "../components/ProfilePageComp/TeamMembersProfile";
import Spinner from "../components/ProfilePageComp/Spinner";
import Footer from "./Homepage/Footer";

const ProfilePage = () => {
  const [profile, setProfile] = useState({
    card_template: "",
    card_template_color: "",
    prof_first_name: "",
    prof_last_name: "",
    position: "",
    telephone: "",
    mobile: "",
    personal_linkedin: "",
    prof_email: "",
    bio: "",
    company_card_logo_size: "", // Default or initial size
    // Add any other fields you need
  });

  const [isDirty, setIsDirty] = useState(false);

  // const [isMenuCollapsed, setIsMenuCollapsed] = useState(
  //   window.innerWidth < 768
  // );

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //   const toggleMobileMenu = () => {
  //     setIsMobileMenuOpen(!isMobileMenuOpen);
  //   };
  const [updateMessage, setUpdateMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const [currentView, setCurrentView] = useState("account"); // 'account' or 'company'
  const navigate = useNavigate();

  const [initialState, setInitialState] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchProfile = async () => {
      const config = {
        headers: { Authorization: `Token ${localStorage.getItem("token")}` },
      };
      try {
        const response = await axios.get(
          "https://dev.keepme.gr/api/profile/",
          config
        );
        console.log(response.data);

        setProfile(response.data);
        setInitialState(response.data); // Set the initial state with the fetched data
      } catch (error) {
        console.error(error);
        if (error.response && error.response.status === 401) {
          navigate("/signin");
        }
      }
    };

    fetchProfile();
  }, [navigate, updateMessage]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isDirty) {
        e.preventDefault();
        e.returnValue = "";
        return "";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDirty]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIsDirty(true);
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  useEffect(() => {
    const handleResize = () => {
      //setIsMenuCollapsed(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const displayMessage = (message, type) => {
    setUpdateMessage(message);
    setMessageType(type);
    setTimeout(() => setUpdateMessage(""), 2000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const endpoint =
      currentView === "account" || currentView === "assets"
        ? "api/profile/update/"
        : "api/company/update/";
    const url = `https://dev.keepme.gr/${endpoint}`;

    const formData = new FormData();

    let hasChanges = false;
    // Loop through the current profile state
    Object.keys(profile).forEach((key) => {
      //Iscompanyowner its always true or false!!!

      if (profile[key] === true || profile[key] === false) {
        return;
      }
      // Check if the value has changed from the initial state and is not the profile picture
      if (profile[key] !== initialState[key] && key !== "profile_pic") {
        formData.append(key, profile[key]);
        hasChanges = true;
      }
      if (key === "is_company_owner" || profile[key] !== initialState[key]) {
        formData.append(key, profile[key]);
        hasChanges = true;
      }
    });

    if (!hasChanges) {
      setUpdateMessage("No Changes Made");
      setTimeout(() => setUpdateMessage(""), 3000); // Clear message after 10 seconds
      return;
    }

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };

    try {
      const response = await axios.put(url, formData, config);
      console.log(response);

      // Update success message

      displayMessage("Update successful!", "success");
      setIsDirty(false);
      setTimeout(() => setUpdateMessage(""), 10000); // Clear message after 10 seconds
    } catch (error) {
      console.error(error);
      // Update failure message
      displayMessage("Update failed!", "error");

      setTimeout(() => setUpdateMessage(""), 10000); // Clear message after 10 seconds
    }
  };

  // Function to handle view switch
  const switchView = (view) => {
    setCurrentView(view);
    setIsMobileMenuOpen(false); // Close the mobile menu when a menu item is clicked
  };

  const handleProfilePicChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("profile_pic", file);

      setLoading(true); // Show spinner

      try {
        const response = await axios.put(
          "https://dev.keepme.gr/api/profile/update/", // Ensure this URL is correct
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Token ${localStorage.getItem("token")}`,
            },
          }
        );
        // Assuming the server responds with the path to the new profile picture
        // if (response.data && response.data.profile_pic) {
        setProfile((prevState) => ({
          ...prevState,
          profile_pic: response.data.profile_pic, // Update the profile state
        }));
        // }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // Hide spinner
      }
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token"); // Clear the token from localStorage
    navigate("/signin"); // Redirect to the login page
  };

  return (
    <div className="container pt-5">
      <main className="page-wrapper">
        <HeadNavProfile
          currentView={currentView}
          setCurrentView={setCurrentView}
          setIsMobileMenuOpen={setIsMobileMenuOpen}
          navigate={navigate}
          logo={logo}
          isMobileMenuOpen={isMobileMenuOpen}
          // qrCodeTypes={qrCodeTypes}
          // handleUpdateQRCodes={handleUpdateQRCodes}
        />
        <section className="container pt-3">
          <div className="row">
            <aside className="col-lg-3 col-md-4 border-end pb-4 mt-n4">
              {/* <aside className="col-lg-3 col-md-4  border-end pb-5 mt-n5"> */}
              <div className="position-sticky top-0">
                <div className="text-center pt-5 ">
                  {(windowWidth >= 769 || currentView === "account") && (
                    <div className="d-flex flex-column justify-content-center align-items-center position-relative mx-auto mt-2 mt-lg-4 pt-5 mb-3">
                      {/* Show spinner if loading, else show the image */}
                      {loading ? (
                        <>
                          <Spinner />
                          <img
                            src={`${profile.profile_pic}` || avatar}
                            className="profile-pic rounded-circle-fixed bg-opacity"
                            width="180"
                            height="180"
                            alt="Profile"
                          />
                        </>
                      ) : (
                        <img
                          src={`${profile.profile_pic}` || avatar}
                          className="profile-pic rounded-circle-fixed image-shadow"
                          width="180"
                          height="180"
                          alt="Profile"
                        />
                      )}

                      <button
                        type="button"
                        className="shadow-lg bottom-0 start-10 mt-3 change-pic-button"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title="Change picture"
                        aria-label="Change picture"
                        onClick={() =>
                          document.getElementById("profilePicInput").click()
                        }
                      >
                        Change Pic
                      </button>

                      <input
                        type="file"
                        id="profilePicInput"
                        style={{ display: "none" }}
                        onChange={handleProfilePicChange}
                      />
                    </div>
                  )}

                  {windowWidth >= 769 || currentView === "account" ? (
                    <React.Fragment>
                      <h2 className="headers-text mb-1">
                        {profile.prof_first_name}
                      </h2>
                      <p className="mb-3 pb-3 smallheaders-text">
                        {profile.company_full_name}
                      </p>
                    </React.Fragment>
                  ) : null}
                  <div
                    id="account-menu"
                    className="list-group list-group-flush d-none d-lg-block"
                  >
                    <button
                      onClick={() => switchView("account")}
                      className={`profilepage-button d-flex align-items-center ${
                        currentView === "account" ? "active" : ""
                      }`}
                    >
                      <i className="bx bx-user-circle fs-xl opacity-60 me-2"></i>{" "}
                      {/* Changed to user-circle icon for Account Details */}{" "}
                      Account Details
                    </button>
                    <button
                      onClick={() => switchView("company")}
                      className={`profilepage-button d-flex border-bottom align-items-center ${
                        currentView === "company" ? "active" : ""
                      }`}
                    >
                      <i className="bx bx-buildings fs-xl opacity-60 me-2"></i>{" "}
                      {/* Changed to buildings icon for Company Details */}{" "}
                      Company Details
                    </button>
                    {/* Correct the onClick function if needed for different views */}

                    <button
                      onClick={() => switchView("assets")}
                      className={`profilepage-button d-flex align-items-center ${
                        currentView === "assets" ? "active" : ""
                      }`}
                    >
                      <i className="bx bx-card fs-xl opacity-60 me-2"></i>{" "}
                      {/* Changed to card icon for My Digital Card */} My
                      Digital Card
                    </button>

                    <button
                      onClick={() => switchView("qrcodes")}
                      className={`profilepage-button d-flex align-items-center ${
                        currentView === "qrcodes" ? "active" : ""
                      }`}
                    >
                      <i className="bx bx-qr fs-xl opacity-60 me-2"></i>{" "}
                      {/* Changed to card icon for My Digital Card */} My QR
                      Codes
                    </button>

                    <button
                      onClick={() => switchView("applewallet")}
                      className={`profilepage-button d-flex border-bottom align-items-center ${
                        currentView === "applewallet" ? "active" : ""
                      }`}
                    >
                      <i className="bx bx-wallet fs-xl opacity-60 me-2"></i>{" "}
                      {/* Changed to wallet icon for Apple Wallet Cards */}{" "}
                      Apple Wallet Cards
                    </button>
                    <button
                      onClick={() => switchView("team")}
                      className={`profilepage-button d-flex align-items-center ${
                        currentView === "team" ? "active" : ""
                      }`}
                    >
                      <i className="bx bx-group fs-xl opacity-60 me-2"></i>{" "}
                      {/* Changed to wallet icon for Apple Wallet Cards */} Team
                      Members
                    </button>
                    <button
                      onClick={() => switchView("security")}
                      className={`profilepage-button d-flex border-bottom align-items-center ${
                        currentView === "security" ? "active" : ""
                      }`}
                    >
                      <i className="bx bx-lock-alt fs-xl opacity-60 me-2"></i>{" "}
                      {/* Changed to wallet icon for Apple Wallet Cards */}{" "}
                      Security
                    </button>

                    <button
                      onClick={() => handleLogout()}
                      className={`profilepage-button d-flex align-items-center ${
                        currentView === "logout" ? "active" : ""
                      }`}
                    >
                      <i className="bx bx-log-out fs-xl opacity-60 me-2"></i>
                      Log Out
                    </button>
                  </div>
                </div>
              </div>
            </aside>

            {/* Main Content */}
            {/* User Profile Content */}
            <div className="col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-sm-4 mt-md-0">
              {currentView === "account" && (
                <UserProfileForm
                  profile={profile}
                  setProfile={setProfile}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  updateMessage={updateMessage}
                  messageType={messageType}
                />
              )}

              {/* Company Profile Content */}
              {currentView === "company" && (
                <CompanyProfileForm
                  profile={profile}
                  setProfile={setProfile}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  updateMessage={updateMessage}
                  messageType={messageType}
                />
              )}

              {/* Main content */}
              {currentView === "assets" && (
                <MobileDigitalCardProfile
                  profile={profile}
                  // handleInputChange={handleInputChange}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  updateMessage={updateMessage}
                  messageType={messageType}
                  setProfile={setProfile}
                />
              )}

              {/* Main content */}
              {currentView === "qrcodes" && (
                <QRCodesProfile
                  profile={profile}
                  updateMessage={updateMessage}
                  messageType={messageType}
                  setUpdateMessage={setUpdateMessage}
                  displayMessage={displayMessage}
                />
              )}

              {/* <div class="col-md-12 offset-lg-0 pb-5 mb-lg-2 pt-md-5 mt-n3 mt-md-0"> */}

              {currentView === "applewallet" && (
                <AppleWalletCardProfile
                  profile={profile}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  updateMessage={updateMessage}
                  messageType={messageType}
                  navigate={navigate}
                />
              )}

              {currentView === "security" && (
                <AccountSecurityProfile profile={profile} />
              )}

              {currentView === "team" && (
                <TeamMembersProfile profile={profile} />
              )}
            </div>
            {/* </div> */}
            {/* </div> */}
          </div>
        </section>
      </main>

      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default ProfilePage;
