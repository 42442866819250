import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo.svg"; // Adjust path as necessary
import signinBackground from "../../assets/img/account/signin-bg.jpg"; // Import the background image
import { Link } from "react-router-dom";
import axios from "axios";

function UserAssign() {
  const navigate = useNavigate();

  const handleContinueWithoutCompany = async () => {
    try {
      const response = await axios.post(
        "https://dev.keepme.gr/api/link-to-default-company/",
        {},
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(response.data.message); // Success message
      navigate("/profile");
    } catch (error) {
      console.error("Error linking to default company:", error);
      // Handle error (e.g., show an error message)
    }
  };

  return (
    <>
      <header className="header navbar navbar-expand-lg position-absolute navbar-sticky">
        <div className="container px-3">
          <a href="/" className="navbar-brand pe-3">
            <img src={logo} width="47" alt="Silicon" /> Silicon
          </a>
        </div>
      </header>

      <main className="page-wrapper">
        <section className=" h-100 pt-5 pb-4">
          <div className="container d-flex flex-wrap justify-content-center justify-content-xl-start h-100 pt-5">
            <div
              className="w-100 align-self-end pt-1 pt-md-4 pb-4"
              style={{ maxWidth: "526px" }}
            >
              <main>
                <h1 className="text-start pb-3 text-xl-start">
                  Your profile is not yet linked to any company
                </h1>
                <button
                  className="btn btn-primary shadow-primary mt-5 btn-lg w-100"
                  onClick={() => navigate("/register-company")}
                >
                  Register New Company
                </button>
                <button
                  className="btn btn-primary shadow-primary mt-5 btn-lg w-100"
                  onClick={() => navigate("/join-company")}
                >
                  Enter Existing Company (with invite link)
                </button>

                <button
                  className="btn btn-primary shadow-primary mt-5 btn-lg w-100"
                  onClick={handleContinueWithoutCompany}
                >
                  Continue without Company (Personal Profile)
                </button>
              </main>
            </div>
          </div>
          <div
            className="position-absolute top-0 end-0 w-50 h-100 bg-position-center bg-repeat-0 bg-size-cover d-none d-xl-block"
            style={{ backgroundImage: `url(${signinBackground})` }}
          />
        </section>
      </main>
    </>
  );
}

export default UserAssign;
