// src/components/Footer.js
import React from "react";
import logo from "../../assets/img/keepme_logo_green.png"; // Adjust path as necessary
import "../../assets/css/KeepMeFooter.css";
import instaIcon from "../../assets/buttons/instagramGreen.png";
import facebookIcon from "../../assets/buttons/facebookGreen.png";
import tiktokIcon from "../../assets/buttons/tiktokGreen.png";
import linkedinIcon from "../../assets/buttons/linkedinGreen.png";

const Footer = () => {
  return (
    <div className="footer">
      <div className="navbar-brand text-dark footer-logo">
        <a href="/" target="_blank" rel="noopener noreferrer">
          <img src={logo} alt="Silicon" onClick={() => window} />
        </a>
        <span>Silicon</span>
      </div>
      <ul className="footer-links">
        <li>Company</li>
        <li>Features</li>
        <li>Digital Card</li>
        <li>About</li>
        <li>Contact</li>
      </ul>
      <div className="footer-social-icon">
        <div className="footer-icons-container">
          <a href="/profile" target="_blank" rel="noopener noreferrer">
            <img src={instaIcon} alt="" className="footer-social-img" />
          </a>
        </div>
        <div className="footer-icons-container">
          <a href="/profile" target="_blank" rel="noopener noreferrer">
            <img src={linkedinIcon} alt="" className="footer-social-img" />
          </a>
        </div>
        <div className="footer-icons-container">
          <a href="/profile" target="_blank" rel="noopener noreferrer">
            <img src={facebookIcon} alt="" className="footer-social-img" />
          </a>
        </div>
        <div className="footer-icons-container">
          <a href="/profile" target="_blank" rel="noopener noreferrer">
            <img src={tiktokIcon} alt="" className="footer-social-img" />
          </a>
        </div>
      </div>
      <div className="footer-copyright">
        <hr />
        <p>
          © 2024{" "}
          <a href="/" target="_blank" rel="noopener noreferrer">
            <img
              src={logo}
              alt="Silicon"
              className="keepme-rights-logo"
              onClick={() => window}
            />
          </a>
          . All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
