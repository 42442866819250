// src/App.js
import React from "react";
import Header from "../components/Homepage/Header";
import Footer from "../components/Homepage/Footer";
import MainContent from "../components/Homepage/MainContent";

function App() {
  return (
    <div>
      <Header />
      <MainContent />
      <Footer />
    </div>
  );
}

export default App;
