// src/components/Header.js
import React from "react";
import logo from "../../assets/img/keepme_logo_green.png"; // Adjust path as necessary

const Header = () => {
  return (
    <header className="header navbar navbar-expand-lg bg-light navbar-sticky">
      <div className="container px-3">
        <a href="/" className="navbar-brand pe-3">
          <img src={logo} width="150" alt="Silicon" />
        </a>

        <div id="navbarNav" className="offcanvas offcanvas-end">
          <div className="offcanvas-header border-bottom">
            <h5 className="offcanvas-title">Menu</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            {/* Add additional navigation links here */}
          </div>
        </div>
        <a
          href="https://dev.keepme.gr/signin/"
          className="btn btn-primary btn-sm fs-sm rounded d-none d-lg-inline-flex"
          target="_blank"
          rel="noopener"
        >
          <i className="bx bx-log-in fs-5 lh-1 me-1" />
          &nbsp;Login
        </a>
      </div>
    </header>
  );
};

export default Header;
