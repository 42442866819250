import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo.svg"; // Adjust path as necessary
import signinBackground from "../../assets/img/account/signin-bg.jpg"; // Import the background image
import { Link } from "react-router-dom";

function SignUp() {
  const [formData, setFormData] = useState({
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password2: "",
    // acceptTerms: false,
  });
  const [usernameAvailable, setUsernameAvailable] = useState(true);
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const [usernameError, setUsernameError] = useState("");

  const handleUsernameChange = async (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({ ...prevState, username: value }));

    if (value.trim() === "") {
      setUsernameError("Username is required.");
      return;
    }

    try {
      const response = await axios.get(
        `https://dev.keepme.gr/api/check-username/?username=${value}`
      );
      if (!response.data.available) {
        setUsernameError("Username is already taken.");
        setUsernameAvailable(false);
      } else {
        setUsernameError("");
        setUsernameAvailable(true);
      }
    } catch (err) {
      console.error("Error checking username availability:", err);
      setUsernameError("Failed to check username availability.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.password2) {
      setError("Passwords do not match.");
      return;
    }

    if (!usernameAvailable) {
      setError("Please fix the errors before submitting.");
      return;
    }

    // if (!formData.acceptTerms) {
    //   setError("You must accept the terms and conditions.");
    //   return;
    // }
    try {
      const response = await axios.post("https://dev.keepme.gr/api/register/", {
        username: formData.username,
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        password: formData.password,
        password2: formData.password2,
      });
      if (response.status === 201) {
        navigate("/registration-success"); // Redirect to success page
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response);
        const errors = err.response.data.error || {};
        const message = errors.message || "An unknown error occurred.";
        const details = errors.details;
        let errorString = message;
        if (details) {
          errorString += Object.entries(details)
            .map(([key, value]) => `\n${key}: ${value.join(", ")}`)
            .join("");
        }
        setError(errorString);
      } else {
        setError("Failed to connect to the registration service.");
      }
    }
  };

  return (
    <>
      <header className="header navbar navbar-expand-lg position-absolute navbar-sticky">
        <div className="container px-3">
          <a href="/" className="navbar-brand pe-3">
            <img src={logo} width="47" alt="Silicon" /> Silicon
          </a>
        </div>
      </header>

      <main className="page-wrapper">
        <section className=" h-100 pt-5 pb-4">
          <div className="container d-flex flex-wrap justify-content-center justify-content-xl-start h-100 pt-5">
            <div
              className="w-100 align-self-end pt-1 pt-md-4 pb-4"
              style={{ maxWidth: "526px" }}
            >
              <h1 className="text-center text-xl-start">Create Account</h1>
              <p className="text-start text-xl-start pb-3 mb-3">
                Already have an account?
                <Link to="/signin" className="btn btn-link btn-lg ">
                  Sign in here.
                </Link>
              </p>

              <form
                className="needs-validation"
                noValidate
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="col-12 mb-4">
                    <label htmlFor="username" className="form-label fs-base">
                      Username
                    </label>
                    <input
                      type="text"
                      id="username"
                      name="username"
                      className="form-control form-control-lg"
                      required
                      value={formData.username}
                      onChange={handleChange}
                      onBlur={handleUsernameChange}
                    />
                    {usernameError && (
                      <div className="alert alert-danger mt-2" role="alert">
                        {usernameError}
                      </div>
                    )}
                  </div>

                  <div className="col-sm-6">
                    <div className="mb-4">
                      <label htmlFor="" className="form-label fs-base">
                        First Name
                      </label>
                      <input
                        type="text"
                        id="first_name"
                        name="first_name"
                        className="form-control form-control-lg"
                        required
                        value={formData.first_name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-4">
                      <label htmlFor="last_name" className="form-label fs-base">
                        Last Name
                      </label>
                      <input
                        type="text"
                        id="last_name"
                        name="last_name"
                        className="form-control form-control-lg"
                        required
                        value={formData.last_name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 mb-4">
                    <label htmlFor="email" className="form-label fs-base">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="form-control form-control-lg"
                      required
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12 mb-4">
                    <label htmlFor="password" className="form-label fs-base">
                      Password
                    </label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      className="form-control form-control-lg"
                      required
                      value={formData.password}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12 mb-4">
                    <label
                      htmlFor="confirmPassword"
                      className="form-label fs-base"
                    >
                      Confirm password
                    </label>
                    <input
                      type="password"
                      id="password2"
                      name="password2"
                      className="form-control form-control-lg"
                      required
                      value={formData.password2}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {error && (
                  <div className="alert alert-danger" role="alert">
                    {error.split("\n").map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </div>
                )}
                <button
                  type="submit"
                  className="btn btn-primary shadow-primary btn-lg w-100"
                >
                  Sign up
                </button>
              </form>
            </div>
          </div>
          <div
            className="position-absolute top-0 end-0 w-50 h-100 bg-position-center bg-repeat-0 bg-size-cover d-none d-xl-block"
            style={{ backgroundImage: `url(${signinBackground})` }}
          ></div>
        </section>
      </main>
    </>
  );
}

export default SignUp;
