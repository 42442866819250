import React from "react";
import axios from "axios";
import "../../assets/css/UserProfileForm.css";

const QRCodesProfile = ({
  profile,
  updateMessage,
  messageType,
  setUpdateMessage,
  displayMessage,
}) => {
  const handleUpdateQRCodes = async () => {
    const url = "https://dev.keepme.gr/api/profile/generate-qr/";
    const config = {
      headers: { Authorization: `Token ${localStorage.getItem("token")}` },
    };

    try {
      console.log(config);
      const response = await axios.post(url, {}, config);
      console.log(response);

      setTimeout(() => setUpdateMessage(""), 10000); // Clear message after 10 seconds
      alert("QR Codes updated successfully!");
    } catch (error) {
      console.error("Error updating QR Codes:", error);
      displayMessage("Update failed!", "error");
      setTimeout(() => setUpdateMessage(""), 10000); // Clear message after 10 seconds
    }
  };

  const qrCodeTypes = [
    {
      type: "black_default",
      title: "QR Code - Black Classic",
      description: "Classic QR code with black inner fill.",
    },
    {
      type: "color_default",
      title: "QR Code - Color Classic",
      description: "Classic QR code with black inner fill.",
    },

    {
      type: "black_rounded",
      title: "QR Code - Black Rounded",
      description: "Rounded square QR code with black inner fill.",
    },

    {
      type: "color_rounded",
      title: "QR Code - Color Rounded",
      description: "Rounded square QR code with color inner fill.",
    },

    {
      type: "black_circle",
      title: "QR Code - Black Dotted",
      description: "Dotted QR code with black inner fill.",
    },
    {
      type: "color_circle",
      title: "QR Code - Color Dotted",
      description: "Dotted QR code with color inner fill.",
    },
    {
      type: "black_gapped_square",
      title: "QR Code Black - Gapped Square",
      description: "Square QR code with gapped edges and black fill.",
    },

    {
      type: "color_gapped_square",
      title: "QR Code Color - Gapped Square",
      description: "Square QR code with gapped edges and color fill.",
    },
  ];

  return (
    <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
      <h1 className="headline-text pt-xl-1 pb-3">My QR Codes</h1>
      {/* Basic info */}

      {/* Access Business Card button */}
      <div>
        <button
          type="button"
          className="btn btn-outline-primary mb-4"
          onClick={handleUpdateQRCodes}
        >
          Update QR Codes
        </button>
        {updateMessage && (
          <div
            style={{
              color: messageType === "success" ? "green" : "red",
              marginTop: "10px",
            }}
          >
            {updateMessage}
          </div>
        )}
      </div>

      <div>
        {qrCodeTypes.map((qr) => (
          <div className="card border-0 shadow-sm  mb-5" key={qr.type}>
            <div className="row g-0 align-items-center">
              <div
                className="col-sm-4 bg-repeat-0 d-flex align-items-center justify-content-center bg-position-center qr-image-div" // Add 'qr-image-div' for media query targeting
                style={{
                  backgroundImage: `url(https://dev.keepme.gr/media/qr/${profile.username}/${qr.type}.jpg)`,
                  minHeight: "12rem",
                  maxHeight: "14rem",
                  backgroundSize: "contain", // Ensure the image scales proportionally
                  backgroundPosition: "center center", // Center the image within the div
                }}
              />
              <div className="col-sm-8">
                <div className="card-body">
                  <h3 className="headers-text pb-1 mb-2">{qr.title}</h3>
                  <p className="mb-4 mb-lg-3">{qr.description}</p>
                  <div className="d-flex justify-content-around flex-wrap">
                    <div className="col-md-6 col-sm-12 mb-2 mb-lg-2">
                      <button
                        onClick={() =>
                          (window.location.href = `https://dev.keepme.gr/media/qr/${profile.username}/${qr.type}.jpg`)
                        }
                        download={`${qr.type}.jpg`}
                        className="save-changes px-5 px-lg-5 me-2"
                      >
                        Download jpg
                      </button>
                    </div>
                    <div className="col-md-6 col-sm-12 mb-2 mb-lg-2">
                      {" "}
                      {/* Adjusted col classes */}
                      <button
                        onClick={() =>
                          (window.location.href = `https://dev.keepme.gr/media/qr/${profile.username}/${qr.type}.png`)
                        }
                        download={`${qr.type}.png`}
                        className="save-changes px-5 px-lg-5 me-2"
                      >
                        Download png
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QRCodesProfile;
