// src/components/PrivateRoute.js
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthState } from "../AuthContext"; // Adjust the import path according to your project structure

const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useAuthState();
  const location = useLocation();

  return isAuthenticated ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default PrivateRoute;
