import React, { useState } from "react";
import phoneFrame from "../../assets/mockups/phone-frame.png"; // Go up two levels and then to assets/mockups
import "../../assets/css/nouislider.min.css"; // Ensure the path is correct
import "../../assets/css/custom.css"; // Ensure the path is correct
import BaseV2 from "./Templates/BaseV2.js";
import Glace from "./Templates/Glace.js";
import PlayV2 from "./Templates/PlayV2.js";
import Fluro from "./Templates/Fluro.js";
import ExecV2Mobile from "./Templates/ExecV2Mobile.js";
import "../../assets/css/UserProfileForm.css";

const MobileDigitalCardProfile = ({
  profile,
  handleChange,
  handleSubmit,
  updateMessage,
  messageType,
  setProfile, // Assuming setProfile is passed as a prop from parent
}) => {
  const [templateHasColor, setTemplateHasColor] = useState(true);
  const [templateColor, setTemplateColor] = useState(null);

  const setColor = (template) => {
    if (template.hasColors === "yes") {
      setTemplateHasColor(true);
      setTemplateColor(template.colors[0]);
      setProfile((prevProfile) => ({
        ...prevProfile,
        card_template_color: template.colors[0],
      }));
      setProfile((prevProfile) => ({
        ...prevProfile,
        card_template: template.title,
      }));
    } else {
      setTemplateHasColor(false);
    }
    setSelectedTemplate(template);

    if (template.title === "PlayV2") {
      console.log("asdadsds");
      handleChange({
        target: {
          name: "card_template",
          value: template.title,
        },
      });
      handleChange({
        target: {
          name: "card_template_color",
          value: null,
        },
      });
    }
  };

  const templates = [
    {
      id: 1,
      title: "BaseV2",
      description: "Description of Template 1",
      hasColors: "yes",
      colors: ["white", "black"],
      fontColor: "black",
    },
    {
      id: 2,
      title: "ExecV2",
      description: "Description of Template 2",
      hasColors: "yes",
      colors: ["gray", "pink", "gold", "black", "blue"],
      fontColor: "black",
    },
    {
      id: 3,
      title: "Fluro",
      description: "Description of Template 3",
      hasColors: "yes",
      colors: ["red", "green", "blue"],
      fontColor: "black",
    },
    {
      id: 4,
      title: "PlayV2",
      description: "Description of Template 4",
      hasColors: "no",
      fontColor: "black",
    },
    {
      id: 5,
      title: "Glace",
      description: "Description of Template 5",
      hasColors: "yes",
      colors: ["white", "black"],
      fontColor: "black",
    },
  ];

  const [selectedTemplate, setSelectedTemplate] = useState(templates[0]);

  const copyClipBoard = () => {
    if (navigator.clipboard && window.isSecureContext) {
      // Check if the clipboard API is available and in a secure context
      navigator.clipboard
        .writeText(profile.digital_card_url)
        .then(() => {
          const notification = document.getElementById("notification");
          notification.classList.add("show");
          setTimeout(() => {
            notification.classList.remove("show");
          }, 2000); // Notification will be visible for 2 seconds
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    } else {
      // Fallback or show some error message if clipboard API is not available
      alert("Clipboard not supported or page is not secure (HTTPS required).");
    }
  };

  return (
    <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
      <h1 className="headline-text pt-xl-1 pb-3">My Digital Card</h1>
      {/* Basic info */}
      <h2 className="h5 headers-text headers-green mb-4">Card URL</h2>

      <div className="row pb-5 border-bottom mb-4">
        <div className="col-sm-12 mb-2">
          {/* Info text */}
          <div className="mb-2 ">
            <small className="text-info">
              This is the link for your digital business card.
            </small>
          </div>
          <div className="input-group mb-3">
            <input
              type="url"
              id="digital_card_url"
              name="digital_card_url"
              value={profile.digital_card_url}
              className="form-control-custom form-control-lg input-field overflow-hidden "
              readOnly // Making it readOnly since it's a URL to be copied
              onClick={() => {
                copyClipBoard();
              }}
            />
            <button
              className="btn btn-outline-secondary"
              type="button"
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                bottom: 0,
                zIndex: 5,
              }}
              onClick={() => {
                copyClipBoard();
              }}
            >
              <i className="bx bx-copy"></i>
            </button>
          </div>
          <div id="notification" className="notification">
            Copied to clipboard successfully!
          </div>

          {/* Access Business Card button */}
          <div>
            <button
              onClick={() =>
                window.open(
                  profile.digital_card_url +
                    "/" +
                    profile.card_template +
                    "/" +
                    profile.card_template_color,
                  "_blank"
                )
              }
              className="save-changes mt-3"
            >
              Access Business Card
            </button>
          </div>
        </div>
      </div>
      <h2 className="h5 headers-text headers-green mt-4">Preview</h2>

      <div className="d-flex justify-content-around reverse-on-small">
        <div className="col-sm-12 col-lg-6  mb-4 align-self-center">
          <form
            className="needs-validation  pb-2 pb-lg-4"
            noValidate
            onSubmit={handleSubmit}
          >
            {/* Templates */}
            <div className="col-12 mb-4">
              <label
                htmlFor="company_card_color"
                className="headers-text mt-5 preview-mobilesize-dissapear"
              >
                Templates
              </label>
              <div className="container">
                <div
                  className="scroll-container gap-3 "
                  style={{ cursor: "pointer" }}
                >
                  {templates.map((template) => (
                    <div
                      className="col-lg-3 col-md-4 col-sm-6 mb-3 d-flex justify-content-center template-card"
                      key={template.id}
                      onClick={() => setColor(template)}
                      style={{
                        flex: "0 0 auto", // Prevent flex item from growing or shrinking
                        width: "175px", // Fixed width for each item
                      }}
                    >
                      <div
                        className="upc-preview-phone position-relative"
                        style={{
                          height: "350px", // Adjust height
                          width: "100%", // Full width of the container
                          maxWidth: "175px", // Fixed width to ensure scrolling
                          margin: "0 auto",
                          borderRadius: "25px", // Ensure rounded corners
                          overflow: "hidden", // Clip content that overflows the container
                          position: "relative", // Ensure the frame and template stay in place
                        }}
                      >
                        {/* Place the phone frame as a background image */}
                        <img
                          src={phoneFrame}
                          className="img-fluid img-expand"
                          alt="Phone Frame"
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            zIndex: 2,
                            padding: "5px",
                            borderRadius: "25px", // Same border-radius as the container
                          }}
                        />

                        {/* Conditionally rendered user profile card */}
                        <div
                          className="position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center"
                          style={{
                            width: "100%", // Adjust width to fit within the phone image
                            height: "100%", // Adjust height to fit within the phone image
                            zIndex: 1,
                            borderRadius: "25px", // Ensure the content inside has rounded corners
                            overflow: "hidden", // Clip anything outside the rounded area
                          }}
                        >
                          {profile.company_name && profile.username ? (
                            <div
                              className="position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center"
                              style={{
                                width: "95%", // Adjust width to fit within the phone image
                                height: "99%", // Adjust height to fit within the phone image
                                zIndex: 1,
                                borderRadius: "25px", // Apply rounded corners
                                overflow: "hidden", // Clip any content that exceeds the border
                              }}
                            >
                              {template.title === "BaseV2" ? (
                                <div className="template-scaledown2">
                                  <BaseV2
                                    templateColor={template.colors[0]}
                                    companyName={profile.company_name}
                                    username={profile.username}
                                    isPreview={true}
                                  />
                                </div>
                              ) : null}
                              {template.title === "ExecV2" ? (
                                <div className="template-scaledown2-ExecV2">
                                  <ExecV2Mobile
                                    templateColor={template.colors[0]}
                                    companyName={profile.company_name}
                                    username={profile.username}
                                    isPreview={true}
                                  />
                                </div>
                              ) : null}
                              {template.title === "Fluro" ? (
                                <div className="template-scaledown2">
                                  <Fluro
                                    templateColor={template.colors[0]}
                                    companyName={profile.company_name}
                                    username={profile.username}
                                    isPreview={true}
                                  />
                                </div>
                              ) : null}
                              {template.title === "PlayV2" ? (
                                <div className="template-scaledown2">
                                  <PlayV2
                                    companyName={profile.company_name}
                                    username={profile.username}
                                    isPreview={true}
                                  />
                                </div>
                              ) : null}
                              {template.title === "Glace" ? (
                                <div className="template-scaledown2">
                                  <Glace
                                    templateColor={template.colors[0]}
                                    companyName={profile.company_name}
                                    username={profile.username}
                                    isPreview={true}
                                  />
                                </div>
                              ) : null}
                            </div>
                          ) : (
                            <div className="text-center">
                              Waiting for profile data...
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {templateHasColor && selectedTemplate.colors && (
              <div
                className="d-flex gap-3 justify-content-center mb-3 p-3"
                style={{ cursor: "pointer" }}
              >
                {selectedTemplate.colors.map((color, index) => (
                  <div
                    key={index}
                    value={selectedTemplate.title}
                    style={{
                      backgroundColor: color,
                      padding: "2px",
                      width: "30px",
                      height: "30px",
                      display: "flex",
                      borderWidth: ".1em",
                      borderRadius: "100%",
                      marginBottom: "5px",
                      border:
                        color === templateColor
                          ? "2px solid #333"
                          : "2px solid #ddd",
                      boxShadow:
                        color === templateColor
                          ? "0 0 4px rgba(0, 0, 0, 0.5)"
                          : "none",
                      transition:
                        "border-color 0.2s ease, box-shadow 0.3s ease",
                    }}
                    onClick={() => {
                      setTemplateColor(color);
                      handleChange({
                        target: {
                          name: "card_template_color",
                          value: color,
                        },
                      });
                      handleChange({
                        target: {
                          name: "card_template",
                          value: selectedTemplate.title,
                        },
                      });
                    }}
                  ></div>
                ))}
              </div>
            )}

            <div className="d-flex flex-column justify-content-center align-items-center mb-3 save-changes-container">
              {" "}
              <div className="d-flex align-items-center">
                <button
                  type="submit"
                  disabled={!profile.is_company_owner} // Disable if not company owner
                  className="save-changes"
                >
                  Save changes
                </button>
              </div>
              {updateMessage && (
                <div
                  className="mt-4"
                  style={{
                    color: messageType === "success" ? "green" : "red",
                    marginTop: "10px",
                  }}
                >
                  {updateMessage}
                </div>
              )}
            </div>
          </form>
        </div>

        <div className="col-lg-6 col-md-12 preview-mobilesize-dissapear">
          <div
            className="upc-preview-phone position-relative img-expand2"
            style={{
              height: "600px",
              width: "300px",
              maxWidth: "300px",
              margin: "0 auto",
            }}
          >
            {/* Place the phone frame as a background image */}
            <img
              src={phoneFrame}
              className="img-fluid img-expand2"
              alt="Phone Frame"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
                zIndex: 2,
              }}
            />

            {/* Conditionally rendered user profile card */}
            <div
              className="position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center preview-mobilesize-dissapear"
              style={{
                width: "96%", // Adjust width to fit within the phone image
                height: "98%", // Adjust height to fit within the phone image
                zIndex: 1,
                overflow: "hidden",
                borderRadius: "60px",
              }}
            >
              {selectedTemplate.title === "BaseV2" ? (
                <div className="template-scaledown">
                  <BaseV2
                    templateColor={templateColor}
                    companyName={profile.company_name}
                    username={profile.username}
                    isPreview={true}
                  />
                </div>
              ) : null}
              {selectedTemplate.title === "ExecV2" ? (
                <div className="template-scaledown-ExecV2">
                  <ExecV2Mobile
                    templateColor={templateColor}
                    companyName={profile.company_name}
                    username={profile.username}
                    isPreview={true}
                  />
                </div>
              ) : null}
              {selectedTemplate.title === "Fluro" ? (
                <div className="template-scaledown">
                  <Fluro
                    templateColor={templateColor}
                    companyName={profile.company_name}
                    username={profile.username}
                    isPreview={true}
                  />
                </div>
              ) : null}
              {selectedTemplate.title === "PlayV2" ? (
                <div className="template-scaledown">
                  <PlayV2
                    companyName={profile.company_name}
                    username={profile.username}
                    isPreview={true}
                  />
                </div>
              ) : null}
              {selectedTemplate.title === "Glace" ? (
                <div className="template-scaledown">
                  <Glace
                    templateColor={templateColor}
                    companyName={profile.company_name}
                    username={profile.username}
                    isPreview={true}
                  />
                </div>
              ) : null}
              {selectedTemplate.title ? null : (
                <div className="template-scaledown">
                  <BaseV2
                    templateColor={templateColor}
                    companyName={profile.company_name}
                    username={profile.username}
                    isPreview={true}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* </form> */}
    </div>
  );
};

export default MobileDigitalCardProfile;
