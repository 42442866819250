// src/UserProfile.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import keepme from "../../../assets/buttons/keepme.png";
import { useParams } from "react-router-dom";
import "../../../assets/css/TemplatesCSS/execV2mobile.css"; // Ensure this CSS file exists and styles your components
import { saveAs } from "file-saver";
import { Helmet } from "react-helmet";

function ExecV2({ companyName, username, templateColor, isPreview }) {
  const params = useParams();
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    if (params.companyName && params.username) {
      axios
        .get(
          `https://dev.keepme.gr/api/card/${params.companyName}/${params.username}/`
        )
        .then((response) => {
          setProfile(response.data);
          if (response.data.company_card_color) {
            document.documentElement.style.setProperty(
              "--card-color",
              response.data.company_card_color
            );
          }
          // Dynamically import CSS based on industry
          // importIndustryCss(response.data.industry);
        })
        .catch((error) =>
          console.error(
            "There was an error retrieving the profile data:",
            error
          )
        );
    } else {
      axios
        .get(`https://dev.keepme.gr/api/card/${companyName}/${username}/`)
        .then((response) => {
          setProfile(response.data);
          if (response.data.company_card_color) {
            document.documentElement.style.setProperty(
              "--card-color",
              response.data.company_card_color
            );
          }
          // Dynamically import CSS based on industry
          // importIndustryCss(response.data.industry);
        })
        .catch((error) =>
          console.error(
            "There was an error retrieving the profile data:",
            error
          )
        );
    }
  }, [companyName, username, params.companyName, params.username]);

  useEffect(() => {
    window.addEventListener("load", adjustHeightForMobileUI);
    window.addEventListener("resize", adjustHeightForMobileUI);

    // Cleanup listeners on component unmount
    return () => {
      window.removeEventListener("load", adjustHeightForMobileUI);
      window.removeEventListener("resize", adjustHeightForMobileUI);
    };
  }, []);

  // const importIndustryCss = (industry) => {
  //   // Default CSS file if industry-specific styling is not available
  //   let cssFile = "default.css";
  //   switch (industry) {
  //     case "Dentist":
  //       cssFile = "Dentist.css";
  //       break;
  //     case "Tech":
  //       cssFile = "Tech.css";
  //       break;
  //     case "Education":
  //       cssFile = "Education.css";
  //       break;
  //     // Add more cases as needed
  //   }
  //   import(`../assets/css/styles/${cssFile}`)
  //     .then(() => {
  //       console.log(`Loaded ${cssFile} successfully`);
  //     })
  //     .catch((err) => {
  //       console.error(`Failed to load ${cssFile}:`, err);
  //     });
  // };

  function adjustHeightForMobileUI() {
    const viewportHeight = window.innerHeight; // Get the actual visible viewport height
    const profileCard = document.querySelector(".profile-card"); // Select your main container

    if (profileCard) {
      profileCard.style.height = `${viewportHeight * 0.9}px`; // Adjust to 90% of the viewport height, or as needed
    }
  }

  window.addEventListener("load", adjustHeightForMobileUI);
  window.addEventListener("resize", adjustHeightForMobileUI);

  const downloadVCard = () => {
    const vCardData = [
      "BEGIN:VCARD",
      "VERSION:3.0",
      `N:${profile.last_name};${profile.first_name}`,
      `FN:${profile.first_name} ${profile.last_name}`,
      `TEL;TYPE=WORK,VOICE:${profile.telephone}`,
      `TEL;TYPE=MOBILE,VOICE:${profile.mobile}`,
      `EMAIL;TYPE=WORK,INTERNET:${profile.email}`,
      `ADR;TYPE=WORK:${profile.address};${profile.city};;${profile.postcode};${profile.country}`,
      `ORG:${profile.company}`,
      `TITLE:${profile.position}`,
      "END:VCARD",
    ].join("\n");

    const blob = new Blob([vCardData], { type: "text/vcard;charset=utf-8" });
    saveAs(blob, `${profile.first_name}_${profile.last_name}.vcf`);
  };

  if (!profile) {
    return <div>Loading...</div>;
  }

  const getClassName = (type) => {
    switch (type) {
      case "pink":
        return "ev2mobilepink-background";
      case "gold":
        return "ev2mobilegold-background";
      case "black":
        return "ev2mobileblack-background";
      case "blue":
        return "ev2mobileblue-background";
      case "gray":
        return "ev2mobile-background";
      default:
        return "ev2mobile-background";
    }
  };

  return (
    <div
      className={
        params.card_template_color
          ? getClassName(params.card_template_color)
          : getClassName(templateColor)
      }
    >
      {" "}
      {/* Wrapper div to contain both the profile card and the footer */}
      <Helmet>
        <title>
          {profile ? `${profile.first_name} ${profile.last_name}` : "KeepMe"}
        </title>
      </Helmet>
      <div
        className={`d-flex flex-column ${isPreview ? "ev2mobile-preview" : ""}`}
      >
        <div className="ev2mobile-name-lastname ev2mobile-name">
          {profile.first_name}{" "}
        </div>
        <div className="ev2mobile-name-lastname">{profile.last_name}</div>
        <div className="ev2mobile-position">{profile.position}</div>
        <div className="ev2mobile-company-name">{profile.company}</div>
        <div className="ev2mobile-addbuttons">
          <div className="ev2mobile-addtoapplewallet-button">
            <p>Add to Apple Wallet</p>
          </div>
          <div
            className="ev2mobile-addtocontacts-button"
            onClick={downloadVCard}
          >
            <p>
              {/* <FontAwesomeIcon
              icon={faAddressBook}
              className="ev2mobile-addtocontacts-icon"
            /> */}
              Add to Contacts
            </p>
          </div>
        </div>

        <div className="ev2mobile-emailphonewrapper">
          <div className="ev2mobile-phonewrapper">
            <p className="ev2mobile-phone-text">Telephone</p>
            {profile.telephone && (
              <a href={`tel:${profile.telephone}`} className="ev2mobile-phone">
                {profile.telephone}
              </a>
            )}
            {profile.mobile && (
              <a href={`tel:${profile.mobile}`} className="ev2mobile-mobile">
                {profile.mobile}
              </a>
            )}
          </div>

          {profile.email && (
            <div className="ev2mobile-emailwrapper">
              <p className="ev2mobile-email-text">Email</p>
              <a href={`mailto:${profile.email}`} className="ev2mobile-email">
                {profile.email}
              </a>
            </div>
          )}
        </div>
      </div>
      <div className="ev2mobile-footer">
        <span>Powered by </span>
        <a
          href="https://www.keepme.gr"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={keepme} alt="keepme.gr" />
        </a>
      </div>
    </div>
  );
}

export default ExecV2;
