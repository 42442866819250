import React from "react";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import "../../assets/css/UserProfileForm.css";
import dropdownIcon from "../../assets/buttons/dropdown-icon.png";

const UserProfileForm = ({
  profile,
  setProfile,
  handleChange,
  handleSubmit,
  updateMessage,
  messageType,
}) => {
  const [visibleFields, setVisibleFields] = useState([]);
  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [fieldOptions, setFieldOptions] = useState([
    { id: "personal_linkedin", label: "LinkedIn" },
    { id: "personal_twitter", label: "X (Twitter)" },
    { id: "personal_tiktok", label: "TikTok" },
    { id: "personal_youtube", label: "YouTube" },
    { id: "personal_snapchat", label: "Snapchat" },
    { id: "personal_whatsapp", label: "WhatsApp" },
    { id: "personal_telegram", label: "Telegram" },
    { id: "personal_wechat", label: "WeChat" },
    { id: "personal_pinterest", label: "Pinterest" },
    { id: "personal_spotify", label: "Spotify" },
  ]);

  const handlePersonalCatalogChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("personal_catalog", file);
      try {
        const response = await axios.put(
          "https://dev.keepme.gr/api/profile/update/", // Ensure this URL is correct
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Token ${localStorage.getItem("token")}`,
            },
          }
        );
        // Update state with the new catalog path
        setProfile((prevState) => ({
          ...prevState,
          personal_catalog: response.data.personal_catalog, // Adjust according to actual response structure
        }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleFieldClick = (field) => {
    setFieldOptions((prevFieldOptions) =>
      prevFieldOptions.filter((item) => item.id !== field)
    );
    setVisibleFields((prevFields) => [...prevFields, field]);
    toggleDropdown();
  };

  useEffect(() => {
    setVisibleFields((prevFields) => {
      const newVisibleFields = new Set(prevFields);

      fieldOptions.forEach(({ id }) => {
        if (profile[id]) {
          newVisibleFields.add(id);
          setFieldOptions((prevFieldOptions) =>
            prevFieldOptions.filter((item) => item.id !== id)
          );
        }
      });

      return Array.from(newVisibleFields);
    });
  }, [fieldOptions, profile]);

  function toggleDropdown() {
    const dropdownMenu = document.getElementById("dropdownMenu");
    const dropdownButton = document.getElementById("dropdownMenuButton");

    // Toggle the dropdown menu visibility
    dropdownMenu.classList.toggle("show", !isDropdownOpen);

    // Toggle the button active state
    dropdownButton.classList.toggle("active", !isDropdownOpen);

    setIsDropdownOpen(!isDropdownOpen);
  }

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
      const dropdownButton = document.getElementById("dropdownMenuButton");
      dropdownButton.classList.remove("active");
    }
  };

  useEffect(() => {
    // Add event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
      <h1 className="pt-xl-1 headline-text pb-3">Account Details</h1>
      {/* Basic info */}
      <h2 className="h5 headers-text headers-green mb-4">Basic info</h2>
      <form
        className="needs-validation border-bottom pb-3 pb-lg-4"
        noValidate
        onSubmit={handleSubmit}
      >
        <div className="row pb-2 border-bottom">
          <div className="col-sm-6 mb-4">
            <label
              htmlFor="fn"
              className="form-label  fs-base smallheaders-text"
            >
              First name
            </label>
            <input
              type="text"
              name="prof_first_name"
              value={profile.prof_first_name}
              className="form-control form-control-lg smallheaders-text input-field"
              onChange={handleChange}
              onBlur={handleSubmit}
              required
            />
            <div className="invalid-feedback">
              Please enter your first name!
            </div>
          </div>
          <div className="col-sm-6 mb-4">
            <label
              htmlFor="prof_last_name"
              className="form-label  fs-base smallheaders-text"
            >
              Last name
            </label>
            <input
              type="text"
              id="prof_last_name"
              name="prof_last_name"
              value={profile.prof_last_name || ""}
              className="form-control form-control-lg smallheaders-text input-field"
              onChange={handleChange}
              onBlur={handleSubmit}
              required
            />
            <div className="invalid-feedback">Please enter your last name!</div>
          </div>

          <div className="col-sm-12 mb-4">
            {/* <div className="col-sm-6"> */}
            <label
              htmlFor="position"
              className="form-label fs-base smallheaders-text"
            >
              Position
            </label>
            {/* </div> */}
            <input
              type="text"
              id="position"
              name="position"
              value={profile.position || ""}
              className="form-control form-control-lg smallheaders-text input-field"
              onChange={handleChange}
              onBlur={handleSubmit}
            />
          </div>

          <div className="col-sm-12 mb-4">
            <label
              htmlFor="prof_email"
              className="form-label fs-base smallheaders-text"
            >
              Email address
            </label>
            <input
              type="text"
              id="prof_email"
              name="prof_email"
              value={profile.prof_email || ""}
              className="form-control form-control-lg smallheaders-text input-field"
              onChange={handleChange}
              onBlur={handleSubmit}
            />
            <div className="invalid-feedback">
              Please provide a valid email address!
            </div>
          </div>
          <div className="col-sm-6 mb-4">
            <label
              htmlFor="telephone"
              className="form-label fs-base smallheaders-text"
            >
              Telephone
            </label>
            <input
              type="text"
              id="telephone"
              name="telephone"
              value={profile.telephone || ""}
              className="form-control form-control-lg smallheaders-text input-field"
              onChange={handleChange}
              onBlur={handleSubmit}
            />
          </div>

          <div className="col-sm-6 mb-4">
            <label
              htmlFor="mobile"
              className="form-label fs-base smallheaders-text"
            >
              Mobile
            </label>
            <input
              type="text"
              id="mobile"
              name="mobile"
              value={profile.mobile || ""}
              className="form-control form-control-lg smallheaders-text input-field"
              onChange={handleChange}
              onBlur={handleSubmit}
            />
          </div>
        </div>

        <h2 className="h5 headers-text headers-green my-5">Socials</h2>

        <div className="row pb-2 mt-3 border-bottom">
          {/* Instagram Personal */}
          <div className="col-sm-6 mb-4">
            <label
              htmlFor="personal_instagram"
              className="form-label fs-base smallheaders-text"
            >
              Instagram Personal{" "}
              <small className="text-muted">(optional)</small>
            </label>
            <input
              type="url"
              id="personal_instagram"
              name="personal_instagram"
              value={profile.personal_instagram || ""}
              className="form-control form-control-lg input-field"
              onChange={handleChange}
              onBlur={handleSubmit}
              placeholder="Instagram profile URL"
            />
          </div>

          {/* Facebook Personal */}
          <div className="col-sm-6 mb-4">
            <label
              htmlFor="personal_facebook"
              className="form-label fs-base smallheaders-text"
            >
              Facebook Personal <small className="text-muted">(optional)</small>
            </label>
            <input
              type="url"
              id="personal_facebook"
              name="personal_facebook"
              value={profile.personal_facebook || ""}
              className="form-control form-control-lg input-field"
              onChange={handleChange}
              onBlur={handleSubmit}
              placeholder="Facebook profile URL"
            />
          </div>

          {/* Linkedin Personal */}

          {visibleFields.includes("personal_linkedin") && (
            <div className="col-sm-6 mb-4">
              <label
                htmlFor="personal_linkedin"
                className="form-label fs-base smallheaders-text"
              >
                LinkedIn Personal{" "}
                <small className="text-muted">(optional)</small>
              </label>
              <input
                type="url"
                id="personal_linkedin"
                name="personal_linkedin"
                value={profile.personal_linkedin || ""}
                className="form-control form-control-lg input-field"
                onChange={handleChange}
                onBlur={handleSubmit}
                placeholder="Linkedin profile URL"
              />
            </div>
          )}

          {/* Twitter Personal */}

          {visibleFields.includes("personal_twitter") && (
            <div className="col-sm-6 mb-4">
              <label
                htmlFor="personal_twitter"
                className="form-label fs-base smallheaders-text"
              >
                X (Twitter) Personal{" "}
                <small className="text-muted">(optional)</small>
              </label>
              <input
                type="url"
                id="personal_twitter"
                name="personal_twitter"
                value={profile.personal_twitter || ""}
                className="form-control form-control-lg input-field"
                onChange={handleChange}
                onBlur={handleSubmit}
                placeholder="X (Twitter) profile URL"
              />
            </div>
          )}

          {/* TikTok Personal */}

          {visibleFields.includes("personal_tiktok") && (
            <div className="col-sm-6 mb-4">
              <label
                htmlFor="personal_tiktok"
                className="form-label fs-base smallheaders-text"
              >
                TikTok Personal <small className="text-muted">(optional)</small>
              </label>
              <input
                type="url"
                id="personal_tiktok"
                name="personal_tiktok"
                value={profile.personal_tiktok || ""}
                className="form-control form-control-lg input-field"
                onChange={handleChange}
                onBlur={handleSubmit}
                placeholder="TikTok profile URL"
              />
            </div>
          )}

          {/* YouTube */}

          {visibleFields.includes("personal_youtube") && (
            <div className="col-sm-6 mb-4">
              <label
                htmlFor="personal_youtube"
                className="form-label fs-base smallheaders-text"
              >
                YouTube <small className="text-muted">(optional)</small>
              </label>
              <input
                type="url"
                id="personal_youtube"
                name="personal_youtube"
                value={profile.personal_youtube || ""}
                className="form-control form-control-lg input-field"
                onChange={handleChange}
                onBlur={handleSubmit}
                placeholder="YouTube profile URL"
              />
            </div>
          )}

          {/* Snapchat */}

          {visibleFields.includes("personal_snapchat") && (
            <div className="col-sm-6 mb-4">
              <label
                htmlFor="personal_snapchat"
                className="form-label fs-base smallheaders-text"
              >
                Snapchat <small className="text-muted">(optional)</small>
              </label>
              <input
                type="url"
                id="personal_snapchat"
                name="personal_snapchat"
                value={profile.personal_snapchat || ""}
                className="form-control form-control-lg input-field"
                onChange={handleChange}
                onBlur={handleSubmit}
                placeholder="Snapchat profile URL"
              />
            </div>
          )}

          {/* WhatsApp */}

          {visibleFields.includes("personal_whatsapp") && (
            <div className="col-sm-6 mb-4">
              <label
                htmlFor="personal_whatsapp"
                className="form-label fs-base smallheaders-text"
              >
                WhatsApp <small className="text-muted">(optional)</small>
              </label>
              <input
                type="text"
                id="personal_whatsapp"
                name="personal_whatsapp"
                value={profile.personal_whatsapp || ""}
                className="form-control form-control-lg input-field"
                onChange={handleChange}
                onBlur={handleSubmit}
                placeholder="WhatsApp number"
              />
            </div>
          )}

          {/* Telegram */}

          {visibleFields.includes("personal_telegram") && (
            <div className="col-sm-6 mb-4">
              <label
                htmlFor="personal_telegram"
                className="form-label fs-base smallheaders-text"
              >
                Telegram <small className="text-muted">(optional)</small>
              </label>
              <input
                type="text"
                id="personal_telegram"
                name="personal_telegram"
                value={profile.personal_telegram || ""}
                className="form-control form-control-lg input-field"
                onChange={handleChange}
                onBlur={handleSubmit}
                placeholder="Telegram profile URL"
              />
            </div>
          )}

          {/* WeChat */}

          {visibleFields.includes("personal_wechat") && (
            <div className="col-sm-6 mb-4">
              <label
                htmlFor="personal_wechat"
                className="form-label fs-base smallheaders-text"
              >
                WeChat <small className="text-muted">(optional)</small>
              </label>
              <input
                type="text"
                id="personal_wechat"
                name="personal_wechat"
                value={profile.personal_wechat || ""}
                className="form-control form-control-lg input-field"
                onChange={handleChange}
                onBlur={handleSubmit}
                placeholder="WeChat ID"
              />
            </div>
          )}

          {/* Pinterest */}

          {visibleFields.includes("personal_pinterest") && (
            <div className="col-sm-6 mb-4">
              <label
                htmlFor="personal_pinterest"
                className="form-label fs-base smallheaders-text"
              >
                Pinterest <small className="text-muted">(optional)</small>
              </label>
              <input
                type="url"
                id="personal_pinterest"
                name="personal_pinterest"
                value={profile.personal_pinterest || ""}
                className="form-control form-control-lg input-field"
                onChange={handleChange}
                onBlur={handleSubmit}
                placeholder="Pinterest profile URL"
              />
            </div>
          )}

          {/* Spotify */}

          {visibleFields.includes("personal_spotify") && (
            <div className="col-sm-6 mb-4">
              <label
                htmlFor="personal_spotify"
                className="form-label fs-base smallheaders-text"
              >
                Spotify <small className="text-muted">(optional)</small>
              </label>
              <input
                type="url"
                id="personal_spotify"
                name="personal_spotify"
                value={profile.personal_spotify || ""}
                className="form-control form-control-lg input-field"
                onChange={handleChange}
                onBlur={handleSubmit}
                placeholder="Spotify profile URL"
              />
            </div>
          )}

          {/* Show more button */}

          {fieldOptions.length === 0 ? null : (
            <div className="col-12 mb-4">
              <div className="dropdown" ref={dropdownRef}>
                <button
                  className="dropdown-togglee"
                  type="button"
                  id="dropdownMenuButton"
                  onClick={() => toggleDropdown()}
                >
                  Add More Links
                  <img
                    src={dropdownIcon}
                    alt=">"
                    className="dropdown-icon arrow"
                  />
                </button>
                <div
                  className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}
                  id="dropdownMenu"
                >
                  {fieldOptions.length > 0 ? (
                    <>
                      <h5 className="dropdown-header smallheaders-text">
                        Add more fields
                      </h5>
                      <div className="dropdown-divider"></div>
                      {fieldOptions
                        .filter((field) => !visibleFields.includes(field.id))
                        .map((field) => (
                          <button
                            key={field.id}
                            className="dropdown-item smallheaders-text"
                            type="button"
                            onClick={() => handleFieldClick(field.id)}
                          >
                            {field.label}
                          </button>
                        ))}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* <h2 className="h5 text-primary mb-3 mt-3">Other links</h2>
        <label htmlFor="Other_Links" className="form-label fs-base">
          Use the below to add any other links in you account page
        </label>
        <div className="row pb-2 mt-2 border-bottom">
          <div className="col-sm-6 mb-4">
            <label
              htmlFor="personal_link1_title"
              className="form-label fs-base"
            >
              Link Title <small className="text-muted"></small>
            </label>
            <input
              type="url"
              id="personal_link1_title"
              name="personal_link1_title"
              value={profile.personal_pinterest || ""}
              className="form-control form-control-lg"
              onChange={handleChange}
              onBlur={handleSubmit}
              placeholder="Title for the Link"
            />
          </div>
          <div className="col-sm-6 mb-4">
            <label htmlFor="personal_link1" className="form-label fs-base">
              URL <small className="text-muted"></small>
            </label>
            <input
              type="url"
              id="personal_link1"
              name="personal_link1"
              value={profile.personal_spotify || ""}
              className="form-control form-control-lg"
              onChange={handleChange}
              onBlur={handleSubmit}
              placeholder="Link URL"
            />
          </div>

          <div className="col-sm-6 mb-4">
            <label
              htmlFor="personal_link2_title"
              className="form-label fs-base"
            >
              Link Title <small className="text-muted"></small>
            </label>
            <input
              type="text"
              id="personal_link2_title"
              name="personal_link2_title"
              value={profile.personal_pinterest || ""}
              className="form-control form-control-lg"
              onChange={handleChange}
              onBlur={handleSubmit}
              placeholder="Title for the Link"
            />
          </div>
          <div className="col-sm-6 mb-4">
            <label htmlFor="personal_link2" className="form-label fs-base">
              URL <small className="text-muted"></small>
            </label>
            <input
              type="url"
              id="personal_link2"
              name="personal_link2"
              value={profile.personal_spotify || ""}
              className="form-control form-control-lg"
              onChange={handleChange}
              onBlur={handleSubmit}
              placeholder="Link URL"
            />
          </div>
          <div className="col-sm-6 mb-4">
            <label
              htmlFor="personal_link1_title"
              className="form-label fs-base"
            >
              Link Title <small className="text-muted"></small>
            </label>
            <input
              type="text"
              id="personal_link3_title"
              name="personal_link3_title"
              value={profile.personal_pinterest || ""}
              className="form-control form-control-lg"
              onChange={handleChange}
              onBlur={handleSubmit}
              placeholder="Title for the Link"
            />
          </div>
          <div className="col-sm-6 mb-4">
            <label htmlFor="personal_link3" className="form-label fs-base">
              URL <small className="text-muted"></small>
            </label>
            <input
              type="url"
              id="personal_link3"
              name="personal_link3"
              value={profile.personal_spotify || ""}
              className="form-control form-control-lg"
              onChange={handleChange}
              onBlur={handleSubmit}
              placeholder="Link URL"
            />
          </div>
        </div> */}

        <h2 className="h5 headers-text headers-green my-4">Files</h2>

        <label htmlFor="Files" className="form-label fs-base smallheaders-text">
          Upload the Personal Catalog/Brochure/Portfolio (max 15mb){" "}
        </label>
        <div className="row pb-5 mt-3 mb-4 border-bottom justify-content-center">
          {/* Catalog Nameing */}
          <div className="col-sm-6">
            <label
              htmlFor="personal_catalog_title"
              className="form-label fs-base smallheaders-text"
            >
              Display Title for the file
            </label>
            <input
              type="text"
              id="personal_catalog_title"
              name="personal_catalog_title"
              value={profile.personal_catalog_title || ""}
              className="form-control form-control-lg smallheaders-text input-field"
              onChange={handleChange}
            />
          </div>

          {/* Personal Product Catalog - Now a file input */}
          <div className="col-sm-6">
            {" "}
            {/* Adjusted for full width */}
            <label
              htmlFor="personal_catalog"
              className="form-label fs-base smallheaders-text"
            >
              File Upload
            </label>
            <input
              type="file"
              id="personal_catalog"
              name="personal_catalog"
              className="form-control form-control-lg"
              onChange={handlePersonalCatalogChange}
            />
            {/* Display the file name if available */}
            {profile.personal_catalog && (
              <a
                href={`${profile.personal_catalog}`}
                target="_blank"
                rel="noopener noreferrer"
                className="fs-sm"
              >
                Download Current Catalog
              </a>
            )}
          </div>
        </div>
        <div className="row pb-2 mt-3 ">
          <div className="d-flex justify-content-center mt-3 mb-3">
            <button type="reset" className="cancel-changes me-3">
              Cancel
            </button>
            <button type="submit" className="save-changes">
              Save Changes
            </button>
          </div>
          {updateMessage && (
            <div
              className="py-2"
              style={{
                color: messageType === "success" ? "green" : "red",
                marginTop: "10px",
              }}
            >
              {updateMessage}
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default UserProfileForm;
