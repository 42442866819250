// src/UserProfile.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import "../assets/css/UserProfileAppleWalletPreview.css"; // Ensure this CSS file exists and styles your components

function UserProfileAppleWalletPreview({
  companyName,
  username,
  company_wallet_card_fontcolor,
  company_wallet_card_bgcolor,
}) {
  // const { companyName, username } = useParams();
  const [profile, setProfile] = useState(null);

  const baseImageUrl = "https://dev.keepme.gr"; // URL to prepend to the image path

  useEffect(() => {
    axios
      .get(`https://dev.keepme.gr/api/card/${companyName}/${username}/`)
      .then((response) => {
        setProfile(response.data);
        console.log(response.data);
        if (response.data.company_card_color) {
          document.documentElement.style.setProperty(
            "--card-color",
            response.data.company_card_color
          );
        }
        // Dynamically import CSS based on industry
        // importIndustryCss(response.data.industry);
      })
      .catch((error) =>
        console.error("There was an error retrieving the profile data:", error)
      );
  }, [companyName, username]);

  useEffect(() => {
    window.addEventListener("load", adjustHeightForMobileUI);
    window.addEventListener("resize", adjustHeightForMobileUI);

    // Cleanup listeners on component unmount
    return () => {
      window.removeEventListener("load", adjustHeightForMobileUI);
      window.removeEventListener("resize", adjustHeightForMobileUI);
    };
  }, []);

  function adjustHeightForMobileUI() {
    const viewportHeight = window.innerHeight; // Get the actual visible viewport height
    const profileCard = document.querySelector(".profile-card"); // Select your main container

    if (profileCard) {
      profileCard.style.height = `${viewportHeight * 0.9}px`; // Adjust to 90% of the viewport height, or as needed
    }
  }

  window.addEventListener("load", adjustHeightForMobileUI);
  window.addEventListener("resize", adjustHeightForMobileUI);

  if (!profile) {
    return <div>Loading...</div>;
  }

  return (
    <div
      className="awpc-card-container"
      style={{
        backgroundColor: company_wallet_card_bgcolor || "#ffffff",
        color: company_wallet_card_fontcolor || "#000000", // Default to black if no color provided
      }}
    >
      <div className="awpc-card-header">
        <img
          src={`${baseImageUrl}/media/logos/keepme_logo.png`}
          alt="KeepMe Logo"
          className="awpc-keepme-logo"
        />
        <span className="awpc-business-card-title">BUSINESS CARD</span>
      </div>
      <div className="awpc-card-first">
        <div className="awpc-name-section">
          <p className="awpc-label">NAME</p>
          <p className="awpc-name">
            {profile.first_name} {profile.last_name}
          </p>
        </div>
        <div className="awpc-clogo-section">
          <img
            src={`${baseImageUrl}${profile.logo}`}
            alt="Company Logo"
            className="awpc-company-logo"
          />
        </div>
      </div>

      <div className="awpc-card-content">
        <div className="awpc-position-section">
          <p className="awpc-label">POSITION</p>
          <p className="awpc-position">{profile.position}</p>
        </div>
        <div className="awpc-contact-info">
          <div className="awpc-mobile-section">
            <p className="awpc-label">MOBILE</p>
            <p className="awpc-mobile">{profile.mobile}</p>
          </div>
          <div className="awpc-email-section">
            <p className="awpc-label">EMAIL</p>
            <p className="awpc-email">{profile.email}</p>
          </div>
        </div>
      </div>
      <div className="awpc-qr-code-container">
        <img
          src={`${baseImageUrl}/media/qr/${username}/black_default.png`}
          alt="QR Code"
          className="awpc-qr-code"
        />
      </div>
    </div>
  );
}

export default UserProfileAppleWalletPreview;
