import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/keepme_logo_green.png"; // Adjust path as necessary
import signinBackground from "../../assets/img/account/signin-bg.jpg"; // Import the background image
import { useAuthDispatch } from "../../AuthContext"; // Ensure the path to your context file is correct
import { Link } from "react-router-dom";

function SignIn() {
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [loginError, setLoginError] = useState(""); // State to store login error message
  const dispatch = useAuthDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoginError("");
    try {
      const response = await axios.post(
        "https://dev.keepme.gr/api/login/",
        credentials
      );
      dispatch({
        type: "LOGIN",
        payload: { token: response.data.token, user: response.data.user },
      });
      localStorage.setItem("token", response.data.token);
      if (response.data.user.has_company) {
        navigate("/profile");
      } else {
        navigate("/userassign"); // Navigate to the UserAssign page if no company
      }
    } catch (error) {
      console.error("Login error:", error);
      setLoginError("Invalid username or password. Please try again.");
    }
  };

  return (
    <>
      <header className="header navbar navbar-expand-lg position-absolute navbar-sticky">
        <div className="container px-3">
          <a href="/" className="navbar-brand pe-3">
            <img src={logo} width="200" alt="Silicon" />
          </a>
        </div>
      </header>

      <main className="page-wrapper">
        <section className=" h-100 pt-5 pb-4">
          <div className="container d-flex flex-wrap justify-content-center justify-content-xl-start h-100 pt-5">
            <div
              className="w-100 align-self-end pt-1 pt-md-4 pb-4"
              style={{ maxWidth: "526px" }}
            >
              <h1 className="text-center text-xl-start">Welcome Back</h1>
              <form
                className="needs-validation mb-2"
                noValidate
                onSubmit={handleSubmit}
              >
                <div className="position-relative mb-4">
                  <label htmlFor="username" className="form-label fs-base">
                    Username
                  </label>
                  <input
                    type="text"
                    id="username"
                    name="username"
                    value={credentials.username}
                    onChange={handleChange}
                    className="form-control form-control-lg"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="password" className="form-label fs-base">
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={credentials.password}
                    onChange={handleChange}
                    className="form-control form-control-lg"
                    required
                  />
                </div>
                {loginError && <div style={{ color: "red" }}>{loginError}</div>}
                <button
                  type="submit"
                  className="btn btn-primary shadow-primary btn-lg w-100"
                >
                  Sign in
                </button>
              </form>
              <a href="#" className="text-center mt-1">
                <Link
                  to="/password-reset"
                  className="btn btn-link btn-lg w-100"
                >
                  Forgot your password?
                </Link>
              </a>
              <div className="text-center mt-1">
                <Link to="/signup" className="btn btn-link btn-lg w-100">
                  Create your account
                </Link>
              </div>
            </div>
          </div>
          <div
            className="position-absolute top-0 end-0 w-50 h-100 bg-position-center bg-repeat-0 bg-size-cover d-none d-xl-block"
            style={{ backgroundImage: `url(${signinBackground})` }}
          />
        </section>
      </main>
    </>
  );
}

export default SignIn;
