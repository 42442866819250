import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo.svg"; // Adjust path as necessary
import signinBackground from "../../assets/img/account/signin-bg.jpg"; // Import the background image

function RegistrationSuccess() {
  const navigate = useNavigate();

  const handleSignIn = () => {
    navigate("/signin");
  };

  return (
    <>
      <header className="header navbar navbar-expand-lg position-absolute navbar-sticky">
        <div className="container px-3">
          <a href="/" className="navbar-brand pe-3">
            <img src={logo} width="47" alt="Silicon" /> Silicon
          </a>
        </div>
      </header>

      <main className="page-wrapper">
        <section className=" h-100 pt-5 pb-4">
          <div className="container d-flex flex-wrap justify-content-start justify-content-xl-start h-100 pt-5">
            <div
              className="w-100 align-self-end pt-1 pt-md-4 pb-4"
              style={{ maxWidth: "526px" }}
            >
              <h1 className="text-center">Registration Successful!</h1>
              <p className="lead text-center">
                Your registration was successful, you have received an
                activation link to your email address. Please click the link for
                your profile to be activated.
              </p>
              <div className="text-center mt-5">
                <button onClick={handleSignIn} className="btn btn-primary">
                  Go to Sign In
                </button>
              </div>
            </div>
          </div>
          <div
            className="position-absolute top-0 end-0 w-50 h-100 bg-position-center bg-repeat-0 bg-size-cover d-none d-xl-block"
            style={{ backgroundImage: `url(${signinBackground})` }}
          ></div>
        </section>
      </main>
    </>
  );
}

export default RegistrationSuccess;
