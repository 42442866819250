import React, { useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import logo from "../assets/img/logo.svg"; // Adjust path as necessary
import setPasswordBackground from "../assets/img/account/signin-bg.jpg"; // Import the background image

const SetPassword = () => {
  const { token } = useParams();
  const [passwords, setPasswords] = useState({
    password: "",
    confirmPassword: "",
  });
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (passwords.password !== passwords.confirmPassword) {
      setMessage("Passwords do not match");
      return;
    }
    try {
      const response = await axios.post(
        `https://dev.keepme.gr/api/set-password/${token}/`,
        {
          new_password: passwords.password,
          confirm_password: passwords.confirmPassword,
        }
      );
      setMessage(response.data.message);
      if (response.data.message === "Password set successfully") {
        navigate("/signin");
      }
    } catch (error) {
      setMessage(error.response.data.error);
    }
  };

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswords({ ...passwords, [name]: value });
  };

  return (
    <>
      <header className="header navbar navbar-expand-lg position-absolute navbar-sticky">
        <div className="container px-3">
          <a href="/" className="navbar-brand pe-3">
            <img src={logo} width="47" alt="Silicon" /> Silicon
          </a>
        </div>
      </header>

      <main className="page-wrapper">
        <section className=" h-100 pt-5 pb-4">
          <div className="container d-flex flex-wrap justify-content-center justify-content-xl-start h-100 pt-5">
            <div
              className="w-100 align-self-end pt-1 pt-md-4 pb-4"
              style={{ maxWidth: "526px" }}
            >
              <h1 className="text-center text-xl-start">Set Your Password</h1>
              <form
                className="needs-validation mb-2"
                noValidate
                onSubmit={handleSubmit}
              >
                <div className="position-relative mb-4">
                  <label htmlFor="password" className="form-label fs-base">
                    New Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={passwords.password}
                    onChange={handleChange}
                    className="form-control form-control-lg"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="confirmPassword"
                    className="form-label fs-base"
                  >
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={passwords.confirmPassword}
                    onChange={handleChange}
                    className="form-control form-control-lg"
                    required
                  />
                </div>
                {message && (
                  <div
                    style={{
                      color: message.includes("do not match") ? "red" : "green",
                    }}
                  >
                    {message}
                  </div>
                )}
                <button
                  type="submit"
                  className="btn btn-primary shadow-primary btn-lg w-100"
                >
                  Set Password
                </button>
              </form>
            </div>
          </div>
          <div
            className="position-absolute top-0 end-0 w-50 h-100 bg-position-center bg-repeat-0 bg-size-cover d-none d-xl-block"
            style={{ backgroundImage: `url(${setPasswordBackground})` }}
          />
        </section>
      </main>
    </>
  );
};

export default SetPassword;
