import React from "react";
import { useParams } from "react-router-dom";
import PlayV2 from "./PlayV2";
import Glace from "./Glace";
import BaseV2 from "./BaseV2";
import ExecV2 from "./ExecV2";
import Fluro from "./Fluro";

function DisplayTemplate() {
  const params = useParams();
  if (params.card_template === "Fluro") {
    return <Fluro />;
  } else if (params.card_template === "Glace") {
    return <Glace />;
  } else if (params.card_template === "PlayV2") {
    return <PlayV2 />;
  } else if (params.card_template === "ExecV2") {
    return <ExecV2 />;
  } else {
    return <BaseV2 />;
  }
}

export default DisplayTemplate;
