import React, { useState, useEffect, useRef } from "react";

import hero_layer1 from "../../assets/img/homepage/hero/layer01.png";
import hero_layer2 from "../../assets/img/homepage/hero/layer02.png";
import hero_layer3 from "../../assets/img/homepage/hero/layer03.png";
import hero_layer4 from "../../assets/img/homepage/hero/layer04.png";
import hero_playv2 from "../../assets/img/homepage/hero/PlayV2.png";
import hero_fluro from "../../assets/img/homepage/hero/Fluro.png";
import hero_glace from "../../assets/img/homepage/hero/Glace.png";
import hero_execv2 from "../../assets/img/homepage/hero/ExecV2.png";
import hero_basev2 from "../../assets/img/homepage/hero/BaseV2.png";
import "./../../assets/css/Slider.css";

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const intervalRef = useRef(null);
  const containerRef = useRef(null);
  const slides = [
    { image: hero_playv2, alt: "PlayV2" },
    { image: hero_fluro, alt: "Fluro" },
    { image: hero_glace, alt: "Glace" },
    { image: hero_basev2, alt: "BaseV2" },
    { image: hero_execv2, alt: "ExecV2" },
    // Add more slides as needed
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
    resetInterval();
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
    resetInterval();
  };

  useEffect(() => {
    intervalRef.current = setInterval(nextSlide, 5000);
    return () => clearInterval(intervalRef.current);
  }, []);

  const resetInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(nextSlide, 5000);
  };

  const getSlideIndex = (index) => {
    return (index + slides.length) % slides.length;
  };

  const handleMouseMove = (e) => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const x = (e.clientX - rect.left) / rect.width - 0.5;
      const y = (e.clientY - rect.top) / rect.height - 0.5;
      setMousePosition({ x, y });
    }
  };

  const getParallaxStyle = (depth) => {
    const maxMovement = 30; // Maximum pixel movement
    return {
      transform: `translate(${-mousePosition.x * maxMovement * depth}px, ${-mousePosition.y * maxMovement * depth}px)`,
    };
  };
  return (
    <div
      className="parallax mx-auto hero-container"
      style={{ maxWidth: "556px" }}
      onMouseMove={handleMouseMove}
      ref={containerRef}
    >
      <div
        className="parallax-layer"
        data-depth="0.1"
        style={getParallaxStyle(0.1)}
      >
        <img src={hero_layer1} alt="Bubble" />
      </div>
      <div
        className="parallax-layer"
        data-depth="-0.2"
        style={getParallaxStyle(-0.2)}
      >
        <img src={hero_layer2} alt="Bubble" />
      </div>
      <div
        className="parallax-layer"
        data-depth="-0.3"
        style={getParallaxStyle(-0.3)}
      >
        <img src={hero_layer3} alt="Bubble" />
      </div>
      <div
        className="parallax-layer"
        data-depth="0.4"
        style={getParallaxStyle(-0.4)}
      >
        <img src={hero_layer4} alt="Bubble" />
      </div>
      <div className="hero-slides-container">
        {[-1, 0, 1].map((offset) => {
          const index = getSlideIndex(currentSlide + offset);
          return (
            <div
              key={index}
              className={`hero-slide ${offset === 0 ? "active" : offset === -1 ? "prev" : "next"}`}
              data-depth={0.1 + index * 0.1}
            >
              <img
                src={slides[index].image}
                className="hero-slide-img"
                alt={slides[index].alt}
              />
            </div>
          );
        })}
      </div>
      <button
        className="slider-btn prev"
        onClick={prevSlide}
        aria-label="Previous slide"
        style={getParallaxStyle(-0.2)}
      >
        &#9664;
      </button>
      <button
        className="slider-btn next"
        onClick={nextSlide}
        aria-label="Next slide"
        style={getParallaxStyle(0.2)}
      >
        &#9654;
      </button>
    </div>
  );
};

export default Slider;
