// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignIn from "./components/WelcomeComp/SignIn";
import SignUp from "./components/WelcomeComp/SignUp";
import RegistrationSuccess from "./components/WelcomeComp/RegistrationSuccess";
import ActivateAccount from "./components/WelcomeComp/ActivationSuccess";
import PasswordReset from "./components/WelcomeComp/PasswordReset";
import PasswordChange from "./components/WelcomeComp/PasswordChange";
import NoCompanyReg from "./components/WelcomeComp/NoCompanyReg";
import RegisterCompany from "./components/WelcomeComp/RegisterCompany";
import JoinCompany from "./components/WelcomeComp/JoinCompany";

import ProfilePage from "./components/ProfilePage";
import PrivateRoute from "./components/PrivateRoute";
import SetPassword from "./components/SetPassword"; // Ensure this component is correctly imported
import Homepage from "./components/Homepage";
import Homepage1 from "./components/Homepage1";

import "./App.css";
import { AuthProvider } from "./AuthContext"; // Adjust the import path as necessary
import DisplayTemplate from "./components/ProfilePageComp/Templates/DisplayTemplate";

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/home" element={<Homepage1 />} />

            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route
              path="/registration-success"
              element={<RegistrationSuccess />}
            />
            <Route path="/activate/:token" element={<ActivateAccount />} />

            <Route path="/password-reset" element={<PasswordReset />} />
            <Route
              path="/change-password/:token"
              element={<PasswordChange />}
            />
            <Route path="/userassign" element={<NoCompanyReg />} />

            <Route
              path="/register-company"
              element={
                <PrivateRoute>
                  <RegisterCompany />
                </PrivateRoute>
              }
            />
            <Route
              path="/join-company"
              element={
                <PrivateRoute>
                  <JoinCompany />
                </PrivateRoute>
              }
            />

            <Route
              path="/profile"
              element={
                <PrivateRoute>
                  <ProfilePage />
                </PrivateRoute>
              }
            />

            <Route
              path="/card/:companyName/:username/:card_template/:card_template_color"
              element={<DisplayTemplate />}
            />

            <Route path="/set-password/:token" element={<SetPassword />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
