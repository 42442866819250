import React from "react";

import hero_layer1 from "../../assets/img/homepage/hero/layer01.png";
import hero_layer2 from "../../assets/img/homepage/hero/layer02.png";
import hero_layer3 from "../../assets/img/homepage/hero/layer03.png";
import hero_layer4 from "../../assets/img/homepage/hero/layer04.png";
import hero_layer5 from "../../assets/img/homepage/hero/layer05.png";
import hero_layer6 from "../../assets/img/homepage/hero/layer06.png";

const MainContent = () => {
  return (
    <main className="page-wrapper">
      {/* Hero Section */}
      <section className="position-relative overflow-hidden zindex-2 pt-4 pt-md-5">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-xl-6 col-lg-7 col-md-6 col-sm-8 col-10 offset-xl-1 order-md-2">
              <div className="parallax mx-auto" style={{ maxWidth: "556px" }}>
                <div className="parallax-layer" data-depth="0.1">
                  <img src={hero_layer1} alt="Bubble" />
                </div>
                <div className="parallax-layer" data-depth="-0.2">
                  <img src={hero_layer2} alt="Bubble" />
                </div>
                <div className="parallax-layer" data-depth="-0.3">
                  <img src={hero_layer3} alt="Bubble" />
                </div>
                <div className="parallax-layer" data-depth="0.4">
                  <img src={hero_layer4} alt="Bubble" />
                </div>
                <div className="parallax-layer" data-depth="-0.1">
                  <img src={hero_layer5} alt="Screen" />
                </div>
                <div className="parallax-layer" data-depth="0.2">
                  <img src={hero_layer6} alt="Screen" />
                </div>
              </div>
            </div>

            <div className="col-lg-5 col-md-6 text-center text-md-start order-md-1">
              <h1 className="display-5 mb-lg-4">
                Manage your money in one application
              </h1>
              <p className="fs-xl pb-3 pb-lg-0 mb-4 mb-lg-5">
                We offer you a new generation of the mobile banking. Save,
                spend, and manage your money on the go.
              </p>
              <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-md-start"></div>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="position-relative zindex-2 px-3 mt-5 mt-sm-5">
        <div
          className="card border-0 rounded-3 shadow py-5 px-3 px-lg-0 mx-auto"
          style={{ maxWidth: "1560px" }}
        >
          <div className="container py-lg-4 py-xl-5">
            <div className="row pt-md-3 pb-md-2">
              {/* Feature cards and content here */}
            </div>
          </div>
        </div>
      </section>

      {/* How it works (Slider) */}
      <section className="position-relative zindex-2 py-lg-4 py-xl-5">
        <div className="container pb-2 pt-sm-2 py-md-3">
          <div className="row align-items-center justify-content-center py-5 my-sm-2 my-xxl-3">
            {/* Slider content */}
          </div>
        </div>
      </section>

      {/* FAQ */}
      <section className="container py-5 mb-lg-2">
        <div className="row py-2 py-md-4 py-lg-5">
          {/* FAQ accordion items */}
        </div>
      </section>

      {/* Subscription CTA */}
      <section className="container">
        <div className="bg-secondary rounded-3 py-5 px-3 px-sm-4 px-lg-5 px-xl-0">
          <div className="row align-items-center py-lg-4">
            {/* Subscription form and content */}
          </div>
        </div>
      </section>
    </main>
  );
};

export default MainContent;
