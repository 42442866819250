import React, { useState } from "react";
import axios from "axios";
import "../../assets/css/UserProfileForm.css";

const AccountSecurityProfile = ({ profile }) => {
  const [passwords, setPasswords] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });

  const handleChange = (e) => {
    setPasswords({
      ...passwords,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (passwords.newPassword !== passwords.confirmPassword) {
      setAlert({
        visible: true,
        message: "New passwords do not match.",
        type: "danger",
      });
      return;
    }
    try {
      const response = await axios.post(
        "https://dev.keepme.gr/api/change-password/",
        {
          username: localStorage.getItem("username"), // Assuming username is stored in localStorage
          current_password: passwords.currentPassword,
          new_password: passwords.newPassword,
        },
        {
          headers: { Authorization: `Token ${localStorage.getItem("token")}` },
        }
      );
      setAlert({
        visible: true,
        message: response.data.message,
        type: "success",
      });
    } catch (error) {
      setAlert({
        visible: true,
        message: error.response.data.message,
        type: "danger",
      });
    }
  };

  const handleAlertClose = () => {
    setAlert({ ...alert, visible: false });
  };

  return (
    <div className="col-md-8 offset-lg-1 pb-5 mb-lg-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
      <div className="ps-md-3 ps-lg-0 mt-md-2 pt-md-4 pb-md-2">
        <h1 className="h2 pt-xl-1 pb-3">Security</h1>
        <h2 className="h5 headers-text headers-green mb-4">Username</h2>
        <form
          onSubmit={handleSubmit}
          className="needs-validation pb-3 pb-lg-4"
          noValidate
        >
          {/* Current Password Input */}
          <div className="row">
            <div className="col-sm-6 mb-4">
              <label
                htmlFor="cp"
                className="form-label fs-base smallheaders-text"
              >
                Current Username
              </label>
              <input
                type="text"
                id="username"
                name="currentUsername"
                value={profile.username}
                onChange={handleChange}
                className="form-control form-control-lg input-field"
                disabled
              />
            </div>
          </div>
        </form>

        <h2 className="h5 headers-text headers-green mb-4">Password</h2>
        <form
          onSubmit={handleSubmit}
          className="needs-validation border-bottom pb-3 pb-lg-4"
          noValidate
        >
          {/* Current Password Input */}
          <div className="row">
            <div className="col-sm-6 mb-4">
              <label
                htmlFor="cp"
                className="form-label fs-base smallheaders-text"
              >
                Current password
              </label>
              <input
                type="password"
                id="cp"
                name="currentPassword"
                value={passwords.currentPassword}
                onChange={handleChange}
                className="form-control form-control-lg input-field"
                required
              />
            </div>
          </div>
          {/* New Password and Confirmation Inputs */}
          <div className="row">
            <div className="col-sm-6 mb-4">
              <label
                htmlFor="np"
                className="form-label fs-base smallheaders-text"
              >
                New password
              </label>
              <input
                type="password"
                id="np"
                name="newPassword"
                value={passwords.newPassword}
                onChange={handleChange}
                className="form-control form-control-lg input-field"
                required
              />
            </div>
            <div className="col-sm-6 mb-4">
              <label
                htmlFor="cnp"
                className="form-label fs-base smallheaders-text"
              >
                Confirm new password
              </label>
              <input
                type="password"
                id="cnp"
                name="confirmPassword"
                value={passwords.confirmPassword}
                onChange={handleChange}
                className="form-control form-control-lg input-field"
                required
              />
            </div>
          </div>
          {/* Submit Button */}
          <div className="d-flex mb-3">
            <button type="submit" className="save-changes">
              Save changes
            </button>
          </div>
        </form>
        {alert.visible && (
          <div
            className={`alert alert-${alert.type} alert-dismissible fade show`}
            role="alert"
          >
            <span className="fw-bold">
              {alert.type === "success" ? "Success" : "Error"} alert:
            </span>{" "}
            {alert.message}
            <button
              className="btn-close"
              type="button"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={handleAlertClose}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountSecurityProfile;
