import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

function ActivateAccount() {
  const { token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(() => {
    axios
      .get(`https://dev.keepme.gr/api/activate/${token}/`)
      .then(() => {
        setMessage("Account activated successfully.");
        setTimeout(() => navigate("/signin"), 3000); // Delay navigation to allow user to read message
      })
      .catch((error) => {
        setMessage("Failed to activate account. Invalid or expired link.");
        setTimeout(() => navigate("/signup"), 3000); // Delay navigation here as well
      })
      .finally(() => setLoading(false));

    // This will run when the component unmounts
    return () => {
      setLoading(false); // Ensure we clean up state
    };
  }, [token, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {message} {/* Display the message based on the outcome */}
    </div>
  );
}

export default ActivateAccount;
