import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo.svg"; // Adjust path as necessary
import signinBackground from "../../assets/img/account/signin-bg.jpg"; // Import the background image
import { useAuthDispatch } from "../../AuthContext"; // Ensure the path to your context file is correct
import { Link } from "react-router-dom";

function RequestPasswordReset() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        "https://dev.keepme.gr/api/request-reset-email/",
        { email }
      );
      setMessage(response.data.message);
    } catch (error) {
      setMessage("Failed to request password reset.");
    }
  };

  return (
    <>
      <header className="header navbar navbar-expand-lg position-absolute navbar-sticky">
        <div className="container px-3">
          <a href="/" className="navbar-brand pe-3">
            <img src={logo} width="47" alt="Silicon" /> Silicon
          </a>
        </div>
      </header>

      <main className="page-wrapper">
        <section className=" h-100 pt-5 pb-4">
          <div className="container d-flex flex-wrap justify-content-center justify-content-xl-start h-100 pt-5">
            <div
              className="w-100 align-self-end pt-1 pt-md-4 pb-4"
              style={{ maxWidth: "526px" }}
            >
              <h1 className="text-center text-xl-start">Reset Password</h1>
              <form
                className="needs-validation mt-2 mb-2"
                noValidate
                onSubmit={handleSubmit}
              >
                <div className="col-12 mt-2 mb-4">
                  <label htmlFor="email" className="form-label fs-base">
                    Email
                  </label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control form-control-lg"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-primary shadow-primary btn-lg w-100"
                >
                  Request Password Reset
                </button>
                <p>{message}</p>
              </form>
            </div>
          </div>
          <div
            className="position-absolute top-0 end-0 w-50 h-100 bg-position-center bg-repeat-0 bg-size-cover d-none d-xl-block"
            style={{ backgroundImage: `url(${signinBackground})` }}
          />
        </section>
      </main>
    </>
  );
}

export default RequestPasswordReset;
