import React, { createContext, useReducer, useContext, useEffect } from 'react';

const AuthStateContext = createContext();
const AuthDispatchContext = createContext();

const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      localStorage.setItem('token', action.payload.token); // Store token
      return { ...state, isAuthenticated: true, user: action.payload.user, token: action.payload.token };
    case 'LOGOUT':
      localStorage.removeItem('token'); // Remove token
      return { ...state, isAuthenticated: false, user: null, token: null };
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

export const AuthProvider = ({ children }) => {
  const initialState = {
    isAuthenticated: !!localStorage.getItem('token'), // Initial check for token presence
    user: null, 
    token: localStorage.getItem('token') // Retrieve token if present
  };
  const [state, dispatch] = useReducer(authReducer, initialState);

  // This effect updates the state when the app initially loads,
  // ensuring that isAuthenticated reflects the actual token presence.
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      dispatch({ type: 'LOGIN', payload: { token } });
    }
  }, []);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

export const useAuthState = () => useContext(AuthStateContext);
export const useAuthDispatch = () => useContext(AuthDispatchContext);
