// src/UserProfile.js
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import instaIcon from "../../../assets/buttons/whiteinstagram.png";
import facebookIcon from "../../../assets/buttons/facebook.png";
import tiktokIcon from "../../../assets/buttons/TikTok.png";
import linkedinIcon from "../../../assets/buttons/linkedin.png";
import keepme from "../../../assets/buttons/keepme.png";
import instaIconBlack from "../../../assets/buttons/instagramBlack.png";
import facebookIconBlack from "../../../assets/buttons/facebookBlack.png";
import tiktokIconBlack from "../../../assets/buttons/TikTokBlack.png";
import linkedinIconBlack from "../../../assets/buttons/linkedinBlack.png";
import keepmeBlack from "../../../assets/buttons/keepmeBlack.png";
import { useParams } from "react-router-dom";
import "../../../assets/css/TemplatesCSS/baseV2.css"; // Ensure this CSS file exists and styles your components
import { saveAs } from "file-saver";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressBook } from "@fortawesome/free-solid-svg-icons";

import { Helmet } from "react-helmet";

function BaseV2({
  companyName,
  username,

  isPreview,
  templateColor,
}) {
  const params = useParams();
  const [profile, setProfile] = useState(null);

  const baseImageUrl = "https://dev.keepme.gr"; // URL to prepend to the image path
  const logoContainerRef = useRef(null); // Ref for the logo container
  const [template_color, setTemplate_Color] = useState(false);
  useEffect(() => {
    if (params.card_template_color) {
      if (params.card_template_color === "white") {
        setTemplate_Color(false);
      } else {
        setTemplate_Color(true);
      }
    } else {
      if (templateColor === "white") {
        setTemplate_Color(false);
      } else {
        setTemplate_Color(true);
      }
    }
  }, [templateColor, params]);

  useEffect(() => {
    if (params.companyName && params.username) {
      axios
        .get(
          `https://dev.keepme.gr/api/card/${params.companyName}/${params.username}/`
        )
        .then((response) => {
          setProfile(response.data);
          if (response.data.company_card_color) {
            document.documentElement.style.setProperty(
              "--card-color",
              response.data.company_card_color
            );
          }
          // Dynamically import CSS based on industry
          // importIndustryCss(response.data.industry);
        })
        .catch((error) =>
          console.error(
            "There was an error retrieving the profile data:",
            error
          )
        );
    } else {
      axios
        .get(`https://dev.keepme.gr/api/card/${companyName}/${username}/`)
        .then((response) => {
          setProfile(response.data);
          if (response.data.company_card_color) {
            document.documentElement.style.setProperty(
              "--card-color",
              response.data.company_card_color
            );
          }
          // Dynamically import CSS based on industry
          // importIndustryCss(response.data.industry);
        })
        .catch((error) =>
          console.error(
            "There was an error retrieving the profile data:",
            error
          )
        );
    }
  }, [companyName, username, params.username, params.companyName]);

  useEffect(() => {
    axios
      .get(`https://dev.keepme.gr/api/card/${companyName}/${username}/`)
      .then((response) => {
        setProfile(response.data);
        if (response.data.company_card_color) {
          document.documentElement.style.setProperty(
            "--card-color",
            response.data.company_card_color
          );
        }
        // Dynamically import CSS based on industry
        // importIndustryCss(response.data.industry);
      })
      .catch((error) =>
        console.error("There was an error retrieving the profile data:", error)
      );
  }, [companyName, username]);

  useEffect(() => {
    window.addEventListener("load", adjustHeightForMobileUI);
    window.addEventListener("resize", adjustHeightForMobileUI);

    // Cleanup listeners on component unmount
    return () => {
      window.removeEventListener("load", adjustHeightForMobileUI);
      window.removeEventListener("resize", adjustHeightForMobileUI);
    };
  }, []);

  function adjustHeightForMobileUI() {
    const viewportHeight = window.innerHeight; // Get the actual visible viewport height
    const profileCard = document.querySelector(".profile-card"); // Select your main container

    if (profileCard) {
      profileCard.style.height = `${viewportHeight * 0.9}px`; // Adjust to 90% of the viewport height, or as needed
    }
  }

  window.addEventListener("load", adjustHeightForMobileUI);
  window.addEventListener("resize", adjustHeightForMobileUI);

  const downloadVCard = () => {
    const vCardData = [
      "BEGIN:VCARD",
      "VERSION:3.0",
      `N:${profile.last_name};${profile.first_name}`,
      `FN:${profile.first_name} ${profile.last_name}`,
      `TEL;TYPE=WORK,VOICE:${profile.telephone}`,
      `TEL;TYPE=MOBILE,VOICE:${profile.mobile}`,
      `EMAIL;TYPE=WORK,INTERNET:${profile.email}`,
      `ADR;TYPE=WORK:${profile.address};${profile.city};;${profile.postcode};${profile.country}`,
      `ORG:${profile.company}`,
      `TITLE:${profile.position}`,
      "END:VCARD",
    ].join("\n");

    const blob = new Blob([vCardData], { type: "text/vcard;charset=utf-8" });
    saveAs(blob, `${profile.first_name}_${profile.last_name}.vcf`);
  };

  if (!profile) {
    return <div>Loading...</div>;
  }

  // const cardStyle = {
  //   backgroundColor: profile.card_color || '#FFFFFF' // default color if none provided
  // };

  const profileActions = () => {
    // Define an array of objects for each social media type
    const socialMedia = [
      {
        type: "Instagram",
        company: profile.company_instagram_link,
        icon: template_color ? instaIconBlack : instaIcon,
      },
      {
        type: "Facebook",
        company: profile.company_facebook_link,
        icon: template_color ? facebookIconBlack : facebookIcon,
      },
      {
        type: "TikTok",
        company: profile.company_tiktok_link,
        icon: template_color ? tiktokIconBlack : tiktokIcon,
      },
      {
        type: "LinkedIn",
        company: profile.company_linkedin_link,
        icon: template_color ? linkedinIconBlack : linkedinIcon,
      },
    ];

    const buttons = socialMedia
      .flatMap((media) => {
        // Handle cases where only a company link is provided
        const actions = [];
        if (media.personal) {
          actions.push(
            <button
              key={`${media.type}-personal`}
              className="upc-prv-btn"
              onClick={() => window.open(media.personal, "_blank")}
            >
              <FontAwesomeIcon icon={media.icon} className="upc-prv-icon" />
              <span className="upc-prv-btn-text">{`Personal ${media.type}`}</span>
            </button>
          );
        }
        if (media.company) {
          actions.push(
            <div
              key={`${media.type}-company`}
              className="bv2-media-button"
              onClick={() => window.open(media.company, "_blank")}
            >
              <img
                src={media.icon}
                className="bv2-media-button-resize"
                alt=""
              />
            </div>
          );
        }
        return actions;
      })
      .filter(Boolean); // Filter out undefined values (for missing links)

    return <>{buttons}</>;
  };

  return (
    <div className={isPreview ? "bv2-background-preview" : "bv2-background"}>
      {" "}
      {/* Wrapper div to contain both the profile card and the footer */}
      <Helmet>
        <title>
          {profile ? `${profile.first_name} ${profile.last_name}` : "KeepMe"}
        </title>
      </Helmet>
      <div className={` ${isPreview ? "min-vw-100" : "bv2-body-container"}`}>
        {profile.logo && (
          <div className="" ref={logoContainerRef}>
            <img
              src={`${baseImageUrl}${profile.logo}`}
              alt="Company Logo"
              className="bv2-company-logo"
            />
          </div>
        )}
        <div
          className={`${template_color ? "bv2b-company-name" : "bv2-company-name"}`}
        >
          {profile.company}
        </div>
        <div
          className={`${template_color ? "bv2b-name-lastname" : "bv2-name-lastname"}`}
        >
          {profile.first_name}{" "}
        </div>
        <div
          className={`${template_color ? "bv2b-name-lastname" : "bv2-name-lastname"}`}
        >
          {profile.last_name}
        </div>
        <div className={`${template_color ? "bv2b-position" : "bv2-position"}`}>
          {profile.position}
        </div>
        <div className={`${template_color ? "bv2b-pronouns" : "bv2-pronouns"}`}>
          He / Him
        </div>
        {profile.email && (
          <div>
            <p
              className={`${template_color ? "bv2b-email-text" : "bv2-email-text"}`}
            >
              Email
            </p>
            <a
              href={`mailto:${profile.email}`}
              className={`${template_color ? "bv2b-email" : "bv2-email"}`}
            >
              {profile.email}
            </a>
          </div>
        )}
        <div>
          <p
            className={`${template_color ? "bv2b-phone-text" : "bv2-phone-text"}`}
          >
            Telephone
          </p>
          {profile.telephone && (
            <a
              href={`tel:${profile.telephone}`}
              className={`${template_color ? "bv2b-phone" : "bv2-phone"}`}
            >
              {profile.telephone}
            </a>
          )}
        </div>
        {profile.mobile && (
          <a
            href={`tel:${profile.mobile}`}
            className={`${template_color ? "bv2b-mobile" : "bv2-mobile"}`}
          >
            {profile.mobile}
          </a>
        )}
        <div
          className={`${template_color ? "bv2b-podcast-button" : "bv2-podcast-button"}`}
        >
          <p>Podcast</p>
        </div>
        <div
          onClick={() => window.open(profile.company_website, "_blank")}
          className={`${template_color ? "bv2b-website-button" : "bv2-website-button"}`}
        >
          <p>Website</p>
        </div>
        <div
          className={`${template_color ? "bv2b-portofolio-button" : "bv2-portofolio-button"}`}
        >
          <p>Portofolio</p>
        </div>
        <div className="bv2-container-media">{profileActions()}</div>
        <div
          className={`${template_color ? "bv2b-addtoapplewallet-button" : "bv2-addtoapplewallet-button"}`}
        >
          <p>Add to Apple Wallet</p>
        </div>
        <div
          className={`${template_color ? "bv2b-addtocontacts-button" : "bv2-addtocontacts-button"}`}
          onClick={downloadVCard}
        >
          <p>
            <FontAwesomeIcon
              icon={faAddressBook}
              className={`${template_color ? "bv2b-addtocontacts-icon" : "bv2-addtocontacts-icon"}`}
            />
            Add to Contacts
          </p>
        </div>
      </div>
      <div className={`${template_color ? "bv2b-footer" : "bv2-footer"}`}>
        <span>Powered by </span>
        <a
          href="https://www.keepme.gr"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={template_color ? keepmeBlack : keepme} alt="keepme.gr" />
        </a>
      </div>
    </div>
  );
}

export default BaseV2;
