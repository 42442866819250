import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo.svg";
import signinBackground from "../../assets/img/account/signin-bg.jpg";
import { Link } from "react-router-dom";

function RegisterCompany() {
  const [companyData, setCompanyData] = useState({
    name: "",
    full_name: "",
    address: "",
    city: "",
    postcode: "",
    country: "",
  });
  const [error, setError] = useState("");
  const [nameAvailable, setNameAvailable] = useState(true);
  const [nameError, setNameError] = useState("");
  const navigate = useNavigate();

  const handleNameChange = async (e) => {
    const { value } = e.target;
    setCompanyData({ ...companyData, name: value });

    // Regex to check the company short name contains only letters, numbers, and hyphens
    const validNameRegex = /^[A-Za-z0-9-]+$/;

    if (value.trim() === "") {
      setNameError("Company short name is required.");
      setNameAvailable(false);
      return;
    }

    if (!validNameRegex.test(value)) {
      setNameError(
        "Company short name can only include letters, numbers, and hyphens (-). No spaces or special characters are allowed."
      );
      setNameAvailable(false);
      return;
    }

    try {
      const response = await axios.get(
        `https://dev.keepme.gr/api/check-company-name/?name=${value}`
      );
      if (!response.data.available) {
        setNameError("This company short name is already taken.");
        setNameAvailable(false);
      } else {
        setNameError("");
        setNameAvailable(true);
      }
    } catch (err) {
      console.error("Error checking company name availability:", err);
      setNameError("Failed to check company name availability.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompanyData({ ...companyData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!nameAvailable) {
      setError("Please fix the errors before submitting.");
      return;
    }

    try {
      const response = await axios.post(
        "https://dev.keepme.gr/api/register-company/",
        companyData,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        }
      );
      navigate("/profile");
    } catch (err) {
      setError(err.response.data.error || "Failed to register company.");
    }
  };

  return (
    <>
      <header className="header navbar navbar-expand-lg position-absolute navbar-sticky">
        <div className="container px-3">
          <a href="/" className="navbar-brand pe-3">
            <img src={logo} width="47" alt="Silicon" /> Silicon
          </a>
        </div>
      </header>

      <main className="page-wrapper">
        <section className=" h-100 pt-5 pb-4">
          <div className="container d-flex flex-wrap justify-content-center justify-content-xl-start h-100 pt-5">
            <div
              className="w-100 align-self-end pt-1 pt-md-4 pb-4"
              style={{ maxWidth: "526px" }}
            >
              <h1 className="text-center text-xl-start">
                Register New Company
              </h1>

              <form
                className="needs-validation"
                noValidate
                onSubmit={handleSubmit}
              >
                <div className="mb-4">
                  <label htmlFor="name" className="form-label fs-base">
                    Company Short Name (used in URLs) cannot be changed later
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="form-control form-control-lg"
                    required
                    value={companyData.name}
                    onChange={handleChange}
                    onBlur={handleNameChange}
                  />
                  {nameError && (
                    <div className="alert alert-danger mt-2" role="alert">
                      {nameError}
                    </div>
                  )}
                </div>

                <div className="mb-4">
                  <label htmlFor="full_name" className="form-label fs-base">
                    Full Company Name
                  </label>
                  <input
                    type="text"
                    id="full_name"
                    name="full_name"
                    className="form-control form-control-lg"
                    required
                    value={companyData.full_name}
                    onChange={handleChange}
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="address" className="form-label fs-base">
                    Company Address
                  </label>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    className="form-control form-control-lg"
                    value={companyData.address}
                    onChange={handleChange}
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="city" className="form-label fs-base">
                    City
                  </label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    className="form-control form-control-lg"
                    value={companyData.city}
                    onChange={handleChange}
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="postcode" className="form-label fs-base">
                    Postcode
                  </label>
                  <input
                    type="text"
                    id="postcode"
                    name="postcode"
                    className="form-control form-control-lg"
                    value={companyData.postcode}
                    onChange={handleChange}
                  />
                </div>

                {error && (
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                )}
                <button
                  type="submit"
                  className="btn btn-primary shadow-primary btn-lg w-100"
                >
                  Register Company
                </button>
              </form>
            </div>
          </div>
          <div
            className="position-absolute top-0 end-0 w-50 h-100 bg-position-center bg-repeat-0 bg-size-cover d-none d-xl-block"
            style={{ backgroundImage: `url(${signinBackground})` }}
          ></div>
        </section>
      </main>
    </>
  );
}

export default RegisterCompany;
