import React from "react";
import "../../assets/css/profilepage.css";
import "../../assets/css/UserProfileForm.css";

const HeadNavProfile = ({
  currentView,
  setCurrentView,
  setIsMobileMenuOpen,
  navigate,
  logo,
  isMobileMenuOpen,
}) => {
  // Function to handle view switch
  const switchView = (view) => {
    setCurrentView(view);
    setIsMobileMenuOpen(false); // Close the mobile menu when a menu item is clicked
  };

  const handleLogout = () => {
    localStorage.removeItem("token"); // Clear the token from localStorage
    navigate("/signin"); // Redirect to the login page
  };

  return (
    <header className="navbar navbar-expand-lg bg-light border-bottom border-light shadow-sm fixed-top">
      <div className="container px-3">
        {/* Hamburger Menu Toggle Button for Smaller Screens */}

        <a href="index.html" className="navbar-brand mt-2 pe-3 flex-shrink-0">
          <img src={logo} width="150" alt="Silicon" />
        </a>

        <button
          className="navbar-toggler ms-auto d-lg-none"
          data-bs-toggle="offcanvas"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          type="button"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Offcanvas Menu for Mobile, hidden on larger screens */}
        <div
          className={`offcanvas offcanvas-end${
            isMobileMenuOpen ? " show" : ""
          } d-lg-none`}
          tabIndex="-1"
          id="mobileMenu"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title">Menu</h5>
            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={() => setIsMobileMenuOpen(false)}
            ></button>
          </div>
          <div className="navbar-nav offcanvas-body pt-3">
            <button
              className={`nav-profile-button ${
                currentView === "account" ? "active" : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                switchView("account");
              }}
            >
              <i className="bx bx-user-circle fs-xl me-2"></i> Account Details
            </button>
            <button
              className={`nav-profile-button ${
                currentView === "company" ? "active" : ""
              }border-bottom`}
              onClick={(e) => {
                e.preventDefault();
                switchView("company");
              }}
            >
              <i className="bx bx-buildings fs-xl me-2"></i> Company Details
            </button>
            <button
              className={`nav-profile-button ${currentView === "assets" ? "active" : ""}`}
              onClick={(e) => {
                e.preventDefault();
                switchView("assets");
              }}
            >
              <i className="bx bx-card fs-xl me-2"></i> My Digital Card
            </button>
            <button
              className={`nav-profile-button ${
                currentView === "qrcodes" ? "active" : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                switchView("qrcodes");
              }}
            >
              <i className="bx bx-qr fs-xl me-2"></i> My QR Codes
            </button>
            <button
              className={`nav-profile-button ${
                currentView === "applewallet" ? "active" : ""
              }border-bottom`}
              onClick={(e) => {
                e.preventDefault();
                switchView("applewallet");
              }}
            >
              <i className="bx bx-wallet fs-xl me-2"></i> Apple Wallet Cards
            </button>
            <button
              className={`nav-profile-button ${currentView === "team" ? "active" : ""} `}
              onClick={(e) => {
                e.preventDefault();
                switchView("team");
              }}
            >
              <i className="bx bx-group fs-xl me-2"></i> Team Members
            </button>
            <button
              className={`nav-profile-button ${
                currentView === "security" ? "active" : ""
              }border-bottom`}
              onClick={(e) => {
                e.preventDefault();
                switchView("security");
              }}
            >
              <i className="bx bx-lock-alt fs-xl me-2"></i> Security
            </button>
            <button
              className={`nav-profile-button ${
                currentView === "logout" ? "active" : ""
              } `}
              onClick={(e) => {
                e.preventDefault();
                handleLogout();
              }}
            >
              <i className="bx bx-log-out fs-xl me-2"></i> Logout
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeadNavProfile;
