import React, { useState, useEffect } from "react";
import axios from "axios";
import photona from "../../assets/img/avatar/photona.jpeg";
import "../../assets/css/UserProfileForm.css";

const TeamMembersProfile = ({ profile }) => {
  const [users, setUsers] = useState([]);
  const [inviteCode, setInviteCode] = useState("");

  useEffect(() => {
    const fetchInviteCode = async () => {
      try {
        const response = await axios.get(
          "https://dev.keepme.gr/api/get-invite-code/",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.data.invite_code) {
          setInviteCode(response.data.invite_code);
        }
      } catch (error) {
        console.error("Error fetching invite code:", error);
      }
    };

    fetchInviteCode();

    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          "https://dev.keepme.gr/api/company-users/",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("token")}`,
            },
          }
        );
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  // Check if the company is 'me' and render message instead of form
  if (profile.company_name.toLowerCase() === "me") {
    return (
      <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
        <h1 className="headline-text pt-xl-1 pb-3">Team Members</h1>
        <div className="alert alert-info" role="alert">
          You have currently set up your profile as a personal account.
        </div>
      </div>
    );
  }

  return (
    <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
      <h1 className="headline-text pt-xl-1 pb-3">Team Members</h1>

      <div className="mb-2 ">
        <small className="text-info">
          This is the invite code to add new teams members to your company
        </small>
      </div>
      <div className="input-group mb-5">
        <input
          type="url"
          id="inviteCode"
          name="inviteCode"
          value={inviteCode}
          className="form-control form-control-lg input-field"
          readOnly // Making it readOnly since it's a URL to be copied
        />
        <button
          className="btn btn-outline-secondary"
          type="button"
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            bottom: 0,
            zIndex: 5,
          }}
          onClick={() => {
            if (navigator.clipboard && window.isSecureContext) {
              // Check if the clipboard API is available and in a secure context
              navigator.clipboard
                .writeText(inviteCode)
                .then(() => {
                  alert("Copied to clipboard successfully!");
                })
                .catch((err) => {
                  alert("Failed to copy text: " + err);
                });
            } else {
              // Fallback or show some error message if clipboard API is not available
              alert(
                "Clipboard not supported or page is not secure (HTTPS required)."
              );
            }
          }}
        >
          <i className="bx bx-copy"></i>
        </button>
      </div>
      {users.map((user, index) => (
        <div
          key={index}
          className="card border-0 shadow-sm overflow-hidden mb-4"
        >
          <div className="row g-0 align-items-center">
            <div className="col-sm-4 d-flex justify-content-center">
              <img
                src={
                  user.profile_pic
                    ? `https://dev.keepme.gr${user.profile_pic}`
                    : photona
                }
                className="profile-pic-team rounded-4"
                // width="120"
                // height="120"
                alt="Profile"
              />
            </div>
            <div className="col-sm-8">
              <div className="card-body container-fluid">
                <h3 className="h4 mb-1">
                  {user.prof_first_name} {user.prof_last_name}
                </h3>
                <p className="mb-3 mb-lg-3">{user.position}</p>
                <div className="mb-2 mb-lg-2 w-100">
                  <a
                    href={user.digital_card_url}
                    className="col-sm-6  btn btn-secondary px-5 px-lg-5  me-2 "
                  >
                    Access digital card
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TeamMembersProfile;
