import React, { useEffect, useState } from "react";
import keepmelogo from "../assets/img/homepage/keepme_logo_green.png";

import features_payment from "../assets/img/homepage/features/payment.svg";
import features_security from "../assets/img/homepage/features/security.svg";
import features_statistics from "../assets/img/homepage/features/statistics.svg";
import features_support from "../assets/img/homepage/features/support.svg";

import screen_01 from "../assets/img/homepage/screens/01.png";
import screen_02 from "../assets/img/homepage/screens/02.png";
import screen_03 from "../assets/img/homepage/screens/03.png";
import { Link } from "react-router-dom";
import googleplay from "../assets/img/market/googleplay-light.svg";
import googleplayDark from "../assets/img/market/googleplay-dark.svg";
import appstore from "../assets/img/market/appstore-light.svg";
import appstoreDark from "../assets/img/market/appstore-dark.svg";
import bellIcon from "../assets/img/landing/app-showcase-1/notification-icon.svg";
import Footer from "./Homepage/Footer";
import "../assets/css/HomePage.css";
import Slider from "./../components/Homepage/Slider";
import "../assets/css/UserProfileForm.css";

const slides = [
  {
    id: 1,
    image: screen_01,
    title: "Create your account",
    text: "Tellus est ullamcorper diam phasellus. Commodo acine pharetra quam lacinia fames quam nibh in elementum pulvinar consectetur adipiscing. Et viverra maecenas quis cras sed gravida volutpat, cursus enim enim ut nulla netus porta lacus.",
  },
  {
    id: 2,
    image: screen_02,
    title: "Set up payment details",
    text: "Ac condimentum a nisl sagittis, interdum. Et viverra maecenas quis cras sed gravida volutpat, cursus enim. Enim ut nulla netus porta lacus diam. Risus massa fames metus lectus diam maecenas dui. Nibh morbi id purus eget tellus diam.",
  },
  {
    id: 3,
    image: screen_03,
    title: "Start trading",
    text: "Risus massa fames metus lectus diam maecenas dui. Nibh morbi id purus eget tellus diam, integer blandit. Ac condimentum a nisl sagittis, interdum. Et viverra maecenas quis cras sed gravida volutpat, cursus enim. Enim ut nulla netus porta.",
  },
];

const Homepage = () => {
  // LoadExternalScript(); // Call the function to load external script
  const [currentSlide, setCurrentSlide] = useState(0);

  const handlePrev = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  const handleNext = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1
    );
  };

  return (
    <div>
      {/* Wraps everything except footer to push footer to the bottom of the page if there is little content */}
      <main className="page-wrapper">
        {/* Navbar */}
        {/* Remove "navbar-sticky" class to make navigation bar scrollable with the page */}
        <header className="header navbar navbar-expand-lg position-absolute navbar-sticky ">
          <div className="container px-3">
            <a href="#" className="navbar-brand pe-3">
              <Link to="/" className="  ">
                <img src={keepmelogo} width={200} alt="Silicon" />
              </Link>
            </a>

            <div id="navbarNav" className="offcanvas offcanvas-end">
              <div className="offcanvas-header border-bottom">
                <h5 className="offcanvas-title">Menu</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                />
              </div>
              <div className="offcanvas-body"></div>
            </div>

            <button
              type="button"
              className="navbar-toggler"
              data-bs-toggle="offcanvas"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <a
              href="https://dev.keepme.gr/signin/"
              className="login-button"
              target="_blank"
              rel="noopener"
            >
              <i className="bx bx-log-in fs-5 lh-1 me-1" />
              &nbsp;Login
            </a>
          </div>
        </header>

        <div className="position-relative">
          <div
            className="position-absolute top-0 start-0 w-100 h-100 d-dark-mode-none"
            style={{
              background: "linear-gradient(141deg, red 17.3%, blue 78.21%)",
            }}
          ></div>
          <div
            className="position-absolute top-0 start-0 w-100 h-100 d-none d-dark-mode-block"
            style={{
              background:
                "linear-gradient(141deg, rgba(11, 15, 25, 0.01) 17.3%, rgba(255, 255, 255, 0.04) 78.21%)",
            }}
          ></div>
        </div>

        {/* Hero */}
        <section className="position-relative overflow-hidden zindex-2 pt-4 pt-md-5">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              {/* Parallax gfx */}
              <div className="col-xl-6 col-lg-7 col-md-6 col-sm-8 col-10 offset-xl-1 order-md-2">
                {/* <div
                  className="parallax mx-auto container2"
                  style={{ maxWidth: "556px" }}
                >
                  <div className="parallax-layer" data-depth="0.1">
                    <img src={hero_layer1} alt="Bubble" />
                  </div>
                  <div className="parallax-layer" data-depth="-0.2">
                    <img src={hero_layer2} alt="Bubble" />
                  </div>
                  <div className="parallax-layer" data-depth="-0.3">
                    <img src={hero_layer3} alt="Bubble" />
                  </div>
                  <div className="parallax-layer" data-depth="0.4">
                    <img src={hero_layer4} alt="Bubble" />
                  </div>
                  <div className="hero-playv2" data-depth="-0.1">
                    <img src={hero_playv2} alt="Screen" />
                  </div>
                  <div className="hero-fluro" data-depth="0.2">
                    <img src={hero_fluro} alt="Screen" />
                  </div>
                  <div className="hero-glace" data-depth="0.2">
                    <img src={hero_glace} alt="Screen" />
                  </div>
                </div> */}
                <Slider />
              </div>

              {/* Text */}
              <div className="col-lg-5 col-md-6 text-center text-md-start order-md-1">
                <h1 className="mb-lg-4 headline-text-inter">
                  Manage your information in one digital card.
                </h1>
                <p className="body-text-inter">
                  We convert traditional business cards into sleek, eco-friendly
                  digital versions, enabling easy sharing, instant updates, and
                  a modern networking experience.
                </p>
                <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-md-start">
                  <a
                    href="#"
                    className="btn btn-dark btn-lg px-3 py-2 me-sm-3 me-lg-4 mb-3"
                  >
                    <img
                      src={appstore}
                      className="light-mode-img"
                      width="124"
                      alt="App Store"
                    />
                    <img
                      src={appstoreDark}
                      className="dark-mode-img"
                      width="124"
                      alt="App Store"
                    />
                  </a>
                  <a href="#" className="btn btn-dark btn-lg px-3 py-2 mb-sm-3">
                    <img
                      src={googleplay}
                      className="light-mode-img"
                      width="139"
                      alt="Google Play"
                    />
                    <img
                      src={googleplayDark}
                      className="dark-mode-img"
                      width="139"
                      alt="Google Play"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="position-relative zindex-2 px-3 mt-n2 mt-sm-0">
          <div
            className="card border-0 rounded-3 shadow py-5 px-3 px-lg-0 mx-auto"
            style={{ maxWidth: "1560px" }}
          >
            <div className="container py-lg-4 py-xl-5">
              <div className="row pt-md-3 pb-md-2">
                <div className="col-lg-5 d-flex flex-column text-lg-start mb-3 mb-sm-4 mb-lg-0">
                  <h2 className="headline-text-inter text-lg-start letter-spacing-sm mt-4">
                    Revolutionize your networking with digital cards.
                    <div className="headers-green">
                      Manage and share your professional identity
                    </div>{" "}
                    right from your smartphone.
                  </h2>
                  <p className="fs-lg mt-auto">
                    *Serviced by{" "}
                    <span className="fw-semibold">Universal Bank</span>
                  </p>
                </div>
                <div className="col-lg-7 col-xl-6 offset-xl-1">
                  <div className="row g-4 g-xl-5">
                    <div className="col-sm-6">
                      <div className="text-center text-sm-start pe-md-2">
                        <img
                          src={features_payment}
                          style={{
                            filter:
                              "drop-shadow(0px 0.8645132780075073px 4.602016925811768px rgba(99, 102, 241, 0.02)) drop-shadow(0px 2.9037201404571533px 10.33138370513916px rgba(99, 102, 241, 0.05)) drop-shadow(0px 13px 27px rgba(99, 102, 241, 0.17))",
                          }}
                          alt="Payment icon"
                        />
                        <h3 className="headers-text-inter mt-4 mb-2">
                          Effortless Sharing
                        </h3>
                        <p className="mb-2 body-text-inter-homepageinfo">
                          Users can easily share and update their digital
                          business cards through the app, using various sharing
                          methods to connect with others.
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="text-center text-sm-start pe-md-2">
                        <img
                          src={features_security}
                          style={{
                            filter:
                              "drop-shadow(0px 0.8645132780075073px 4.602016925811768px rgba(99, 102, 241, 0.02)) drop-shadow(0px 2.9037201404571533px 10.33138370513916px rgba(99, 102, 241, 0.05)) drop-shadow(0px 13px 27px rgba(99, 102, 241, 0.17))",
                          }}
                          alt="Security icon"
                        />
                        <h3 className="headers-text-inter mt-4 mb-2">
                          Data security
                        </h3>
                        <p className="mb-2 body-text-inter-homepageinfo">
                          The app employs various security measures to protect
                          user data from unauthorized access or theft.
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="text-center text-sm-start pe-md-2">
                        <img
                          src={features_statistics}
                          style={{
                            filter:
                              "drop-shadow(0px 0.8645132780075073px 4.602016925811768px rgba(99, 102, 241, 0.02)) drop-shadow(0px 2.9037201404571533px 10.33138370513916px rgba(99, 102, 241, 0.05)) drop-shadow(0px 13px 27px rgba(99, 102, 241, 0.17))",
                          }}
                          alt="Statistics icon"
                        />
                        <h3 className="headers-text-inter mt-4 mb-2">
                          Insightful Analytics
                        </h3>
                        <p className="mb-2 body-text-inter-homepageinfo">
                          Users can track and analyze their networking
                          activities, viewing detailed statistics such as
                          connections made and engagement metrics over time.
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="text-center text-sm-start pe-md-2">
                        <img
                          src={features_support}
                          style={{
                            filter:
                              "drop-shadow(0px 0.8645132780075073px 4.602016925811768px rgba(99, 102, 241, 0.02)) drop-shadow(0px 2.9037201404571533px 10.33138370513916px rgba(99, 102, 241, 0.04)) drop-shadow(0px 13px 27px rgba(99, 102, 241, 0.17))",
                          }}
                          alt="Support icon"
                        />
                        <h3 className="headers-text-inter mt-4 mb-2">
                          Support 24/7
                        </h3>
                        <p className="mb-2 body-text-inter-homepageinfo">
                          Users can get help or support from the app's customer
                          service team at any time of day or night.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- How it works (Slider) --> */}
        <section className="container">
          <div
            className="text-center pb-4 mb-5 mt-5 mx-auto"
            style={{ maxWidth: "530px" }}
          >
            <h2 className="headline-text-inter">How Does It Work?</h2>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6 pb-4 pb-md-0 mb-2 mb-md-0">
              <div className="position-relative px-5">
                <div
                  className="swiper-tabs zindex-2 mx-auto"
                  style={{ maxWidth: "384px" }}
                >
                  {slides.map((slide) => (
                    <div
                      key={slide.id}
                      className={`swiper-tab ${currentSlide === slides.indexOf(slide) ? "active" : ""}`}
                    >
                      <img src={slide.image} alt="App screen" />
                    </div>
                  ))}
                </div>
                <div
                  className="bg-gradient-primary position-absolute start-0 bottom-0 w-100 opacity-15 rounded-3"
                  style={{ height: "86.5%" }}
                ></div>
              </div>
            </div>
            <div className="col-md-6 col-lg-5 offset-lg-1">
              <div className="ps-md-4 ps-lg-0">
                <div className="text-center text-md-start mt-auto">
                  <div className="slider-createacount-wrapper">
                    {slides.map((slide, index) => (
                      <div
                        key={slide.id}
                        className={`slider-createacount-slide ${currentSlide === index ? "active" : ""}`}
                      >
                        <div className="fs-xl text-muted mb-3 mb-lg-4">
                          step {slide.id}
                        </div>
                        <h3 className="mb-lg-4">{slide.title}</h3>
                        <p>{slide.text}</p>
                      </div>
                    ))}
                  </div>
                  <div className="d-flex justify-content-center justify-content-md-start pt-2 pt-lg-3">
                    <button
                      type="button"
                      onClick={handlePrev}
                      className="btn btn-prev btn-icon btn-sm me-2"
                      aria-label="Previous"
                    >
                      <i className="bx bx-chevron-left"></i>
                    </button>
                    <button
                      type="button"
                      onClick={handleNext}
                      className="btn btn-next btn-icon btn-sm ms-2"
                      aria-label="Next"
                    >
                      <i className="bx bx-chevron-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Subscription CTA */}
        <section className="container subscription-container">
          <div className="bg-secondary rounded-3 py-5 px-3 px-sm-4 px-lg-5 px-xl-0">
            <div className="row align-items-center py-lg-4">
              <div className="col-xl-5 col-md-6 offset-xl-1 mb-4 mb-md-0">
                <div className="d-flex align-items-center">
                  <img src={bellIcon} width={78} alt="Bell icon" />
                  <div className="ps-3 ms-sm-3">
                    <h2 className="h4 mb-0">
                      Subscribe to our newsletter to stay informed about latest
                      updates
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-md-6">
                <div className="ps-lg-5 ms-xl-2">
                  <form
                    className="input-group input-group-lg needs-validation"
                    noValidate
                  >
                    <input
                      type="email"
                      id="subscr-email"
                      className="form-control rounded-start ps-5"
                      placeholder="Your email"
                      required
                    />
                    <i className="bx bx-envelope fs-xl text-muted position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5" />
                    <div className="invalid-tooltip position-absolute top-100 start-0">
                      Please provide a valid email address.
                    </div>
                    <button type="submit" className="login-button">
                      Subscribe
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Homepage;
