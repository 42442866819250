import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import "../../assets/css/UserProfileForm.css";
import dropdownIcon from "../../assets/buttons/dropdown-icon.png";

const CompanyProfileForm = ({
  profile,
  setProfile,
  handleChange,
  handleSubmit,
  updateMessage,
  messageType,
}) => {
  const [visibleFields, setVisibleFields] = useState([]);
  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [fieldOptions, setFieldOptions] = useState([
    { id: "company_snapchat_link", label: "Snapchat" },
    { id: "company_youtube_link", label: "YouTube" },
    { id: "company_google_maps_link", label: "Google Maps" },
    { id: "company_google_rev_link", label: "Google Reviews" },
    { id: "company_twitter_link", label: "X (Twitter)" },
  ]);

  const handleCompanyCatalogChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("product_catalog", file);
      try {
        const response = await axios.put(
          "https://dev.keepme.gr/api/company/update/", // Ensure this URL is correct
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Token ${localStorage.getItem("token")}`,
            },
          }
        );
        // Update state with the new catalog path
        setProfile((prevState) => ({
          ...prevState,
          product_catalog: response.data.product_catalog, // Adjust according to actual response structure
        }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleCompanyLogoChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("logo", file); // Adjust 'company_logo' if your API expects a different key
      try {
        const response = await axios.put(
          "https://dev.keepme.gr/api/company/update/",
          formData,
          {
            // Adjust this URL to your actual API endpoint for uploading company logos
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Token ${localStorage.getItem("token")}`,
            },
          }
        );

        setProfile((prevState) => ({
          ...prevState,
          company_logo: response.data.logo,
        }));
        // Handle success (e.g., showing a success message, re-fetching company details to update the UI, etc.)
      } catch (error) {
        console.error(error);
        // Handle error (e.g., showing an error message)
      }
    }
  };

  const handleFieldClick = (field) => {
    setFieldOptions((prevFieldOptions) =>
      prevFieldOptions.filter((item) => item.id !== field)
    );
    setVisibleFields((prevFields) => [...prevFields, field]);
    toggleDropdown();
  };

  useEffect(() => {
    setVisibleFields((prevFields) => {
      const newVisibleFields = new Set(prevFields);

      fieldOptions.forEach(({ id }) => {
        if (profile[id]) {
          newVisibleFields.add(id);
          setFieldOptions((prevFieldOptions) =>
            prevFieldOptions.filter((item) => item.id !== id)
          );
        }
      });

      return Array.from(newVisibleFields);
    });
  }, [profile, fieldOptions]);

  function toggleDropdown() {
    const dropdownMenu = document.getElementById("dropdownMenu");
    const dropdownButton = document.getElementById("dropdownMenuButton");

    // Toggle the dropdown menu visibility
    dropdownMenu.classList.toggle("show", !isDropdownOpen);

    // Toggle the button active state
    dropdownButton.classList.toggle("active", !isDropdownOpen);

    setIsDropdownOpen(!isDropdownOpen);
  }

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
      const dropdownButton = document.getElementById("dropdownMenuButton");
      dropdownButton.classList.remove("active");
    }
  };

  useEffect(() => {
    // Add event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Check if the company is 'me' and render message instead of form
  if (profile?.company_name?.toLowerCase() === "me") {
    return (
      <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
        <h1 className="headline-text pt-xl-1 pb-3">Company Details</h1>
        <div className="alert alert-info" role="alert">
          You have currently set up your profile as a personal account.
        </div>
      </div>
    );
  }

  return (
    <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
      <h1 className="headline-text pt-xl-1 pb-3">Company Details</h1>
      <div>
        {profile.is_company_owner ? null : (
          <div className="text-danger pt-xl-1 pb-3" role="alert">
            *Only the company manager can make changes to these details.
          </div>
        )}
      </div>
      <h2 className="h5 headers-text headers-green mb-4">Basic info</h2>
      <form
        className="needs-validation border-bottom pb-2 pb-lg-4"
        noValidate
        onSubmit={handleSubmit}
      >
        <div className="row pb-2 border-bottom">
          {/* Company Full Name */}
          <div className="col-12 mb-4">
            <label
              htmlFor="company_full_name"
              className="form-label fs-base smallheaders-text"
            >
              Company Full Name
            </label>
            <input
              type="text"
              id="company_full_name"
              name="company_full_name"
              value={profile.company_full_name || ""}
              className="form-control form-control-lg smallheaders-text input-field"
              onChange={handleChange}
              onBlur={handleSubmit}
              required
              disabled={!profile.is_company_owner} // Disable if not company owner
            />
          </div>

          {/* Company Address */}
          <div className="col-sm-12 mb-4">
            <label
              htmlFor="company_address"
              className="form-label fs-base smallheaders-text"
            >
              Address
            </label>
            <input
              type="text"
              id="company_address"
              name="company_address"
              value={profile.company_address || ""}
              className="form-control form-control-lg smallheaders-text input-field"
              onChange={handleChange}
              onBlur={handleSubmit}
              required
              disabled={!profile.is_company_owner} // Disable if not company owner
            />
          </div>

          {/* Company City */}
          <div className="col-sm-4 mb-4">
            <label
              htmlFor="company_city"
              className="form-label fs-base smallheaders-text"
            >
              City
            </label>
            <input
              type="text"
              id="company_city"
              name="company_city"
              value={profile.company_city || ""}
              className="form-control form-control-lg smallheaders-text input-field"
              onChange={handleChange}
              onBlur={handleSubmit}
              required
              disabled={!profile.is_company_owner} // Disable if not company owner
            />
          </div>

          {/* Company Postcode */}
          <div className="col-sm-4 mb-4">
            <label
              htmlFor="company_postcode"
              className="form-label fs-base smallheaders-text"
            >
              Postcode
            </label>
            <input
              type="text"
              id="company_postcode"
              name="company_postcode"
              value={profile.company_postcode || ""}
              className="form-control form-control-lg smallheaders-text input-field"
              onChange={handleChange}
              onBlur={handleSubmit}
              required
              disabled={!profile.is_company_owner} // Disable if not company owner
            />
          </div>

          {/* Company Country */}
          <div className="col-sm-4 mb-4">
            <label
              htmlFor="company_country"
              className="form-label fs-base smallheaders-text"
            >
              Country
            </label>
            <input
              type="text"
              id="company_country"
              name="company_country"
              value={profile.company_country || ""}
              className="form-control form-control-lg smallheaders-text input-field"
              onChange={handleChange}
              onBlur={handleSubmit}
              required
              disabled={!profile.is_company_owner} // Disable if not company owner
            />
          </div>

          {/* Company Telephone */}
          <div className="col-sm-6 mb-4">
            <label
              htmlFor="company_telephone"
              className="form-label fs-base smallheaders-text"
            >
              Telephone
            </label>
            <input
              type="text"
              id="company_telephone"
              name="company_telephone"
              value={profile.company_telephone || ""}
              className="form-control form-control-lg smallheaders-text input-field"
              onChange={handleChange}
              onBlur={handleSubmit}
              disabled={!profile.is_company_owner} // Disable if not company owner
              placeholder="Company Telephone"
            />
          </div>

          {/* Company Website */}
          <div className="col-12 mb-4">
            <label
              htmlFor="company_website"
              className="form-label fs-base smallheaders-text"
            >
              Website
            </label>
            <input
              type="url"
              id="company_website"
              name="company_website"
              value={profile.company_website || ""}
              className="form-control form-control-lg smallheaders-text input-field"
              onChange={handleChange}
              onBlur={handleSubmit}
              disabled={!profile.is_company_owner} // Disable if not company owner
              placeholder="Website url"
            />
          </div>
        </div>

        <h2 className="h5 headers-text headers-green my-5"> Company Socials</h2>

        <div className="row pb-2 mt-3 border-bottom">
          {/* Instagram */}
          <div className="col-sm-12 col-lg-6 mb-4">
            <label
              htmlFor="company_instagram"
              className="form-label fs-base smallheaders-text"
            >
              Instagram Profile
            </label>
            <input
              type="url"
              id="company_instagram_link"
              name="company_instagram_link"
              value={profile.company_instagram_link || ""}
              className="form-control form-control-lg input-field"
              onChange={handleChange}
              onBlur={handleSubmit}
              placeholder="Instagram profile URL"
            />
          </div>

          {/* Facebook */}
          <div className="col-sm-12 col-lg-6  mb-4">
            <label
              htmlFor="company_facebook_link"
              className="form-label fs-base smallheaders-text"
            >
              Facebook Profile
            </label>
            <input
              type="url"
              id="company_facebook_link"
              name="company_facebook_link"
              value={profile.company_facebook_link || ""}
              className="form-control form-control-lg input-field"
              onChange={handleChange}
              onBlur={handleSubmit}
              placeholder="Facebook profile URL"
            />
          </div>

          {/* Linkedin */}

          <div className="col-sm-12 col-lg-6  mb-4">
            <label
              htmlFor="company_linkedin_link"
              className="form-label fs-base smallheaders-text"
            >
              Linkedin Profile
            </label>
            <input
              type="url"
              id="company_linkedin_link"
              name="company_linkedin_link"
              value={profile.company_linkedin_link || ""}
              className="form-control form-control-lg input-field"
              onChange={handleChange}
              onBlur={handleSubmit}
              placeholder="Linkedin profile URL"
            />
          </div>

          {/* TikTok */}

          <div className="col-sm-12 col-lg-6  mb-4">
            <label
              htmlFor="company_tiktok_link"
              className="form-label fs-base smallheaders-text"
            >
              TikTok Profile
            </label>
            <input
              type="url"
              id="company_tiktok_link"
              name="company_tiktok_link"
              value={profile.company_tiktok_link || ""}
              className="form-control form-control-lg input-field"
              onChange={handleChange}
              onBlur={handleSubmit}
              placeholder="TikTok profile URL"
            />
          </div>

          {/* Twitter */}

          {visibleFields.includes("company_twitter_link") && (
            <div className="col-sm-12 col-lg-6  mb-4">
              <label
                htmlFor="company_twitter_link"
                className="form-label fs-base smallheaders-text"
              >
                X (Twitter) Profile{" "}
                <small className="text-muted">(optional)</small>
              </label>
              <input
                type="url"
                id="company_twitter_link"
                name="company_twitter_link"
                value={profile.company_twitter_link || ""}
                className="form-control form-control-lg input-field"
                onChange={handleChange}
                onBlur={handleSubmit}
                placeholder="X (Twitter) profile URL"
              />
            </div>
          )}

          {/* YouTube */}

          {visibleFields.includes("company_youtube_link") && (
            <div className="col-sm-6 mb-4">
              <label
                htmlFor="company_youtube"
                className="form-label fs-base smallheaders-text"
              >
                YouTube <small className="text-muted"> (optional)</small>
              </label>
              <input
                type="url"
                id="company_youtube_link"
                name="company_youtube_link"
                value={profile.company_youtube_link || ""}
                className="form-control form-control-lg input-field"
                onChange={handleChange}
                onBlur={handleSubmit}
                placeholder="YouTube profile URL"
              />
            </div>
          )}

          {/* Snapchat */}

          {visibleFields.includes("company_snapchat_link") && (
            <div className="col-sm-6 mb-4">
              <label
                htmlFor="company_snapchat"
                className="form-label fs-base smallheaders-text"
              >
                Snapchat <small className="text-muted">(optional)</small>
              </label>
              <input
                type="url"
                id="company_snapchat_link"
                name="company_snapchat_link"
                value={profile.company_snapchat_link || ""}
                className="form-control form-control-lg input-field"
                onChange={handleChange}
                onBlur={handleSubmit}
                placeholder="Snapchat profile URL"
              />
            </div>
          )}

          {/* Googlemaps */}

          {visibleFields.includes("company_google_maps_link") && (
            <div className="col-sm-12 col-lg-6  mb-4">
              <label
                htmlFor="company_google_maps_link"
                className="form-label fs-base smallheaders-text"
              >
                Google Maps Profile{" "}
                <small className="text-muted">(optional)</small>
              </label>
              <input
                type="url"
                id="company_google_maps_link"
                name="company_google_maps_link"
                value={profile.company_google_maps_link || ""}
                className="form-control form-control-lg input-field"
                onChange={handleChange}
                onBlur={handleSubmit}
                placeholder="Snapchat profile URL"
              />
            </div>
          )}

          {/* Google reviews */}

          {visibleFields.includes("company_google_rev_link") && (
            <div className="col-sm-12 col-lg-6  mb-4">
              <label
                htmlFor="company_google_rev_link"
                className="form-label fs-base smallheaders-text"
              >
                Google Review Profile{" "}
                <small className="text-muted">(optional)</small>
              </label>
              <input
                type="url"
                id="company_google_rev_link"
                name="company_google_rev_link"
                value={profile.company_google_rev_link || ""}
                className="form-control form-control-lg input-field"
                onChange={handleChange}
                onBlur={handleSubmit}
                placeholder="Snapchat profile URL"
              />
            </div>
          )}

          {/* Show more button */}

          {fieldOptions.length === 0 ? null : (
            <div className="col-12 mb-4">
              <div className="dropdown" ref={dropdownRef}>
                <button
                  className="dropdown-togglee"
                  type="button"
                  id="dropdownMenuButton"
                  onClick={() => toggleDropdown()}
                >
                  Add More Links
                  <img
                    src={dropdownIcon}
                    alt=">"
                    className="dropdown-icon arrow"
                  />
                </button>
                <div
                  className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}
                  id="dropdownMenu"
                >
                  {fieldOptions.length > 0 ? (
                    <>
                      <h5 className="dropdown-header smallheaders-text">
                        Add more fields
                      </h5>
                      <div className="dropdown-divider"></div>
                      {fieldOptions
                        .filter((field) => !visibleFields.includes(field.id))
                        .map((field) => (
                          <button
                            key={field.id}
                            className="dropdown-item smallheaders-text"
                            type="button"
                            onClick={() => handleFieldClick(field.id)}
                          >
                            {field.label}
                          </button>
                        ))}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </div>

        <h2 className="h5 headers-text headers-green my-4">Files</h2>

        <label
          htmlFor="company_logo"
          className="form-label fs-base smallheaders-text"
        >
          Upload the Company Catalog/Brochure/Portfolio (max 15mb){" "}
        </label>
        <div className="row pb-5 mt-3 mb-4 border-bottom justify-content-center">
          {/* Catalog Nameing */}
          <div className="col-sm-6">
            <label
              htmlFor="company_catalog_title"
              className="form-label fs-base smallheaders-text"
            >
              Display Title for the file
            </label>
            <input
              type="text"
              id="company_catalog_title"
              name="company_catalog_title"
              value={profile.company_catalog_title || ""}
              className="form-control form-control-lg smallheaders-text input-field"
              onChange={handleChange}
              disabled={!profile.is_company_owner} // Disable if not company owner
            />
          </div>

          {/* Company Product Catalog - Now a file input */}
          <div className="col-sm-6">
            {" "}
            {/* Adjusted for full width */}
            <label
              htmlFor="company_product_catalog"
              className="form-label fs-base smallheaders-text"
            >
              File Upload
            </label>
            <input
              type="file"
              id="company_product_catalog"
              name="company_product_catalog"
              className="form-control form-control-lg"
              onChange={handleCompanyCatalogChange}
              disabled={!profile.is_company_owner} // Disable if not company owner
            />
            {/* Display the file name if available */}
            {profile.company_product_catalog && (
              <a
                href={`https://dev.keepme.gr${profile.company_product_catalog}`}
                target="_blank"
                rel="noopener noreferrer"
                className="fs-sm"
              >
                Download Current Catalog
              </a>
            )}
          </div>
        </div>
        <h2 className="h5 headers-text headers-green mb-2 mt-3">Logo</h2>

        {/* Company Logo */}

        <label
          htmlFor="company_logo"
          className="form-label fs-base smallheaders-text"
        >
          Company Logo
        </label>
        <div className="row pb-4 mt-3 mb-4  border-bottom">
          <div className="d-flex justify-content-start align-items-start">
            <div className="col-3 ">
              <img
                src={`https://dev.keepme.gr${profile.company_logo}`}
                // className="profile-pic rounded-circle"
                className="rounded-3"
                // alt="Rounded image"
                width="120"
                height="120" // Ensure the height is the same as the width
                alt="Company Logo"
              />
            </div>
            <div className="col-9  align-self-center align-items-start ">
              <input
                type="file"
                id="company_logo"
                name="company_logo"
                className="form-control form-control-lg"
                onChange={handleCompanyLogoChange}
                disabled={!profile.is_company_owner} // Disable if not company owner
              />
              <div className="col-4 mt-2 align-items-start">
                {profile.company_logo && (
                  <a
                    href={`https://dev.keepme.gr${profile.company_logo}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fs-sm smallheaders-text "
                  >
                    Download Current Logo
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
        <h2 className="h5 headers-text headers-green mb-2 mt-3">Colors</h2>

        {/* Company Logo */}

        <label
          htmlFor="company_logo"
          className="form-label fs-base smallheaders-text"
        >
          Company Colors
        </label>
        <div className="row pb-4 mt-3 mb-4 border-bottom">
          {/* Company Card Color */}

          <div className="d-flex justify-content-center">
            <div className="col-5">
              <input
                type="color"
                id="company_card_color"
                name="company_card_color"
                value={profile.company_card_color || "#ffffff"}
                className="form-control form-control-lg me-2"
                // style={{ width: "auto" }}
                onChange={handleChange}
              />
            </div>
            <div className="col-5 offset-1">
              <input
                type="text"
                id="company_card_color_hex"
                name="company_card_color"
                value={profile.company_card_color || "#ffffff"}
                className="form-control form-control-lg"
                onChange={handleChange}
                placeholder="#ffffff"
              />
            </div>
          </div>
        </div>
        <div className="d-flex gap-3 justify-content-center p-4">
          <button
            type="reset"
            disabled={!profile.is_company_owner} // Disable if not company owner
            className="cancel-changes"
          >
            Cancel
          </button>

          <button
            type="submit"
            disabled={!profile.is_company_owner} // Disable if not company owner
            className="save-changes"
          >
            Save changes
          </button>
        </div>
        {updateMessage && (
          <div
            className="py-2"
            style={{
              color: messageType === "success" ? "green" : "red",
              marginTop: "10px",
            }}
          >
            {updateMessage}
          </div>
        )}
      </form>
    </div>
  );
};

export default CompanyProfileForm;
