// Spinner.js
import React from "react";
import "../../assets/css/Spinner.css"; // Ensure to create this CSS file

const Spinner = () => {
  return (
    <div className="spinner-overlay">
      <div className="spinner"></div>
    </div>
  );
};

export default Spinner;
